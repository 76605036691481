.contact {
  padding-top: 100px;

  .card {
    padding: 45px;

    textarea {
      height: 200px;
    }
  }
}