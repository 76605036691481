.about {
  padding-top: 100px;

  .card {
    padding: 45px;
  }

  .white-b {
    strong {
      color: white;
    }
  }

  .old-logo {
    img {
      margin-top: 15px;
      max-width: 150px;
    }
  }
}