@import '../scss/constants.scss';

.card {
  @include border-radius($border-radius);

  margin: 15px 0px;
  background: $med-gray;
  overflow: hidden;
  transition: transform 300ms ease;
  transform: translateY(0px);

  &:hover {
    transform: translateY(-4px);
    text-decoration: none !important;
  }

  .upper {
    overflow: hidden;
    position: relative;

    @media (min-width: $md-min) {
      max-height: 200px;
    }

    .tags {
      top: 30px;
      right: 0px;
      position: absolute;
      color: white;
      text-align: right;

      .featured {
        display: block;
        background: rgba($black, 0.6);
        margin-bottom: 5px;
        font-weight: bold;
        padding: 6px 15px;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        i,
        svg {
          margin-left: 6px;
        }
      }
    }

    .img-fluid {
      min-height: 200px;
      min-width: 100%;
    }
  }

  .lower {
    padding: 20px;
    color: rgba(white, 0.67) !important;

    b {
      display: block;
      color: rgba(white, 0.87);
      text-decoration: none !important;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin-bottom: 0px;
    }

    .rating {
      display: inline-block;

      i,
      svg {
        margin-right: 3px;
      }

      .fa-star {
        color: $green;
      }

      .fa-star-o {
        color: rgba(white, 0.67);
        opacity: 0.5;
      }
    }

    .left {
      display: inline-block;
      float: left;
    }

    .right {
      display: inline-block;
      float: right;

      i,
      svg {
        color: $red;
        margin-left: 5px;
        animation: pulse 1.5s infinite;
      }
    }
  }

  &.no-hover {
    &:hover {
      transform: translateY(0px);
      text-decoration: none !important;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.8;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

a {
  &:hover {
    text-decoration: none !important;

    .card {
      text-decoration: none !important;
    }
  }
}