body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-results {
  border: solid 1px rgba(white, 0.2);
  padding: 60px 15px;
  border-radius: 2px;
  width: 100%;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
  }
