@import '../scss/constants.scss';

.newsletter, .login .row, .login [class*=col-] {
    // height: auto !important
}

.newsletter .card {
    max-width: none;
}

.newsletter .card {
    padding: 25px;
}

.newsletter ul {
    list-style: none;
}

.newsletter .btn {
    margin: 20px 0 30px !important
}