@import '../scss/constants.scss';

.main--notifications {
  padding-top: calc(#{$header-height} + #{$gutter});
}

.notifications {
  padding: 25px;

  .notification-list {
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;

    li {
      border-bottom: solid 1px rgba($white, 0.1);
      line-height: 1.5;
      padding: 15px 0px;

      b {
        color: white;
      }

      p {
        color: rgba($white, 0.6);
        line-height: 1.5;
        margin-bottom: 0px;
      }

      small {
        color: rgba($white, 0.3);
      }
    }
  }
}