@import '../scss/constants.scss';

.covid-alert {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  text-align: center;
  max-width: 900px;

  a {
    color: white;
    text-decoration: underline;
  }

  @media (max-width: $xs-max) {
    top: 60px;
    width: 100%;
    max-width: none;
    margin: 0;
    border-radius: 0;
    left: 0;
  }
}