.privacy {
  padding-top: 100px;

  .card {
    padding: 45px;
  }

  .white-b {
    strong {
      color: white;
    }
  }
}