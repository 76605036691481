@import '../scss/constants.scss';

.header {
  @include transition(
      background ease 300ms,
      border-color ease 300ms);

  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  height: $header-height;
  padding: $header-padding 0;
  background: rgba($white, 0);
  border: none;
  border-bottom: solid 1px rgba(#ddd, 0);
  border-color: rgba(#ddd, 0);

  &__logo {
    @include transition(
      color ease 300ms);

    display: inline-block;
    height: calc(#{$header-height} - (#{$header-padding} * 2));
    line-height: calc(#{$header-height} - (#{$header-padding} * 2));
    vertical-align: top;
    margin-right: calc(#{$gutter} * 1.25);

    a {
      text-decoration: none;
      color: $white;
      font-weight: 900;
      font-size: 20px;
      position: relative;
      top: -3px;
      left: -5px;

      img {
        height: 45px;
      }
    }

    &.remove-pad {
      margin-right: 0;
      margin-bottom: 0 !important;
    }

    &.mb-force {
      margin-bottom: 15px !important;
    }
  }

  &__nav {
    display: inline-block;
    vertical-align: top;

    a {
      display: inline-block;
      margin-right: $gutter;
      line-height: calc(#{$header-height} - (#{$header-padding} * 2));
      color: rgba($white, 0.87);
      text-decoration: none;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }

      &.active {
        color: $highlight-color;
        cursor: pointer;
      }
    }

    nav {
      display: inline-block;
    }
  }

  &.active {
    @extend .header-active;
  }
}

.fa-bell:before{
  font-size: 18px;
}

.map-toggle-btn{
  background: transparent;
  border: none;
  top: 0px;
  cursor: pointer;
  margin-right: 15px;
}

.map-toggle-btn:hover{
  background: transparent;
  color: $purple;;
}

.header-wrapper {
  &:not(.header-wrapper--) {
    .header {
      @extend .header-active;
    }
  }
}

.header-active {
  background: darken($dark-gray, 5%);
  border-color: darken($dark-gray, 5%);

  .header__logo {
    a {
      color: rgba($white, 1);

      i,svg {
        color: $blue;
      }
    }
  }

  .header__nav {
    a {
      color: rgba($white, 0.76);
    }
  }

  .btn-link {
    color: rgba($white, 0.76);
  }
}

ul.header-buttons {
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: inline-block;
    line-height: calc(#{$header-height} - (#{$header-padding} * 2));
    vertical-align: top;

    &:first-of-type {
      margin-right: 5px;
    }

    .btn {
      display: inline-block;
      position: relative;
      top: -2px;
    }
  }
}

.brandmark {
  @include border-radius(8px);

  display: inline-block;
  vertical-align: top;
  background: $purple;
  height: 26px;
  width: 26px;
  margin-top: 9px;
  margin-right: 13px;
  text-align: center;

  i,svg {
    color: white !important;
    font-size: 12px;
    vertical-align: top;
    position: relative;
    top: 7px;
    left: 0px;
    color: rgba(white, 0.67);
  }

  @media (max-width: $sm-max) {
    @include border-radius(7px);

    height: 24px;
    width: 24px;
    margin-top: 10px;

    i,svg {
      font-size: 10px;
      top: 7px;
    }
  }
}

@media (max-width: $xs-max) {
  .logo-container {

    .header__logo {
      margin-right: 0px;
    }
  }

  .back-button {
    color: rgba(white, 0.87);
    font-size: 20px;
    line-height: $header-height;
    position: relative;
    top: -$header-padding;
  }
}

.header-wrapper-- {
  .back-button {
    i,
    svg {
      display: none;
    }
  }
}

.notification-icon {
  display: flex;
  margin-top: 4px;
  padding-left: 100px;
}

.user-menu {
  white-space: nowrap;

  ul {
    margin-bottom: 0;
    list-style-type: none;

    li {
      display: inline-block;
      line-height: calc(#{$header-height} - (#{$header-padding} * 2));
      vertical-align: top;
      position: relative;

      &:first-of-type {
        margin-right: 5px;
      }

      .btn {
        display: inline-block;
        position: relative;
        top: -2px;
      }

      .dropdown-menu {
        display: none;
        margin-top: 0;

        // keep this visible
        &.notification-dropdown {
          display: block;
          position: absolute;
          right: 0px;
          left: auto;
          background: $med-gray;
        }
      }

      &:hover {
        cursor: default;

        .dropdown-menu {
          display: block;
          position: absolute;
          right: 0px;
          left: auto;
          background: $med-gray;
        }
      }

      &.notification-button {
        margin-right: 20px;
        margin-top: 7px;

        i,
        svg {
          font-size: 33px;
        }

        &:hover {
          i,
          svg {
            cursor: pointer;
            color: white;
          }
        }

        .count {
          @include border-radius(10px);

          position: absolute;
          top: -2px;
          right: -7px;
          background: $red;
          padding: 4px 8px 4px 8px;
          color: white;
          font-size: 12px;
          line-height: 1;
          font-weight: bold;
        }

        .dropdown-menu {
          width: 400px;
          max-width: 100vw;
          padding: 0px;
          height: 500px;
          overflow-x: hidden;
          overflow-y: scroll;
          max-height: calc(100vh - 150px);
        }
      }

      &.user-button {
        line-height: 65px;
        height: 65px;
        position: relative;
        top: -10px;

        img {
          @include border-radius(30px);

          width: 30px;
          height: 30px;
          overflow: hidden;
          margin-right: 12px;
          border: solid 1px rgba($white, 0.67);
          position: relative;
          top: -1px;
        }

        .fa-caret-down {
          margin-left: 6px;
        }

        .dropdown-menu {
          text-align: right;
          padding: 0px;

          a {
            color: rgba($white, 0.87);
            padding-right: 15px;
            padding-left: 15px;

            &:hover {
              opacity: 0.5;
            }
          }

          &.user-dropdown {
            li {
              display: block;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media(min-width: $sm-min) {
  .user-menu {
    display: none !important;
  }
}

.authorized {
  @media(min-width: $sm-min) {
    .action-buttons {
      display: none !important;
    }

    .user-menu {
      display: inline !important;
    }
  }
}

.notifications-mobile-link {
  height: 45px;
  width: 45px;
  display: inline-block;
  font-size: 22px;
  padding: 6px;
  position: relative;

  .count {
    @include border-radius(10px);

    position: absolute;
    top: -2px;
    right: -7px;
    background: $red;
    padding: 4px 8px 4px 8px;
    color: white;
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
  }

  i,svg {
    color: rgba(255, 255, 255, 0.67);
  }
}

// TODO - remove this once auth is working.
.authToggle {
  background: none;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  color: rgba($white, 0.76);
  position: relative;
  left: -6px;
}
