@import '../scss/constants.scss';
.admin {
  padding-top: 100px;

  hr {
    border-top: solid 1px rgba(255,255,255,0.2);
  }
}

.admin-sidebar {
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding-right: 15px;

  @media (max-width: $md-max) {
    width: 100%;
    padding-right: 0;
  }

  nav {
    border-bottom: solid 1px rgba(white, 0.1);
    border-radius: 3px;
    margin-bottom: 30px;

    a {
      display: block;
      background: $med-gray;
      padding: 15px;
      border-bottom: solid 1px rgba(white, 0.1);
      color: rgba(white, 0.7);
      border-left: solid 4px $med-gray;
      position: relative;

      .fa-chevron-right {
        position: absolute;
        right: 15px;
        top: 20px;
        opacity: 0;
      }

      &:hover {
        color: rgba(white, 0.9);
        border-left: solid 4px rgba(white, 0.2);

        .fa-chevron-right {
          opacity: 0.5;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      &.active {
        color: white;
        font-weight: bold;
        border-left: solid 4px $purple;
        cursor: default;

        .fa-chevron-right {
          opacity: 1;
        }
      }
    }
  }
}

.admin-content {
  display: inline-block;
  width: calc(100% - 300px);
  vertical-align: top;
  padding-left: 15px;

  h2 {
    font-size: 22px;
    margin-bottom: 3px;
  }

  .admin-header {
    p {
      span.venue {
        padding-right: 8px;
      }
    }
  }

  .rating {
    display: inline-block;

    i,
    svg {
      margin-right: 3px;
    }

    .fa-star {
      color: $green;
    }

    .fa-star-o {
      color: rgba(white, 0.67);
      opacity: 0.5;
    }
  }

  @media (max-width: $md-max) {
    width: 100%;
    padding-left: 0;
  }

  .venue-select {
    display: inline-block;
    width: 265px;

    @media (max-width: $md-max) {
      width: 100%;
      padding-left: 0;
    }
  }

  .date-select {
    display: inline-block;
    width: 280px;
    padding-left: 15px;

    @media (max-width: $md-max) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.DateRangePicker {
  @media (max-width: $md-max) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.chart-card {
  padding: 60px;

  @media (max-width: $md-max) {
    padding: 30px;
  }
}

.DateRangePickerInput {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;

  @media (max-width: $md-max) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }

  .DateInput {
    background: none !important;
    width: 105px;

    input {
      background: none !important;
      color: white !important;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .DateRangePickerInput_arrow_svg {
    fill: #bbb;
  }

  .DateRangePickerInput_calendarIcon_svg {
    fill: #bbb;
    position: relative;
    top: -1px;
  }

  .DateRangePickerInput_calendarIcon {
    width: auto;
    padding: 0;
    border: 0 !important;
    outline: 0 !important;
  }

  .DateInput_input__focused {
    border-bottom: solid 2px $purple !important;
  }
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.stat-card {
  padding: 30px;

  label {
    margin-bottom: 0px;
    padding-bottom: 5px;
  }

  span {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  .green {
    color: $green;
  }

  .purple {
    color: $purple;
  }

  .red {
    color: $red;
  }
}

.venue-content {
  width: calc(100% - 295px);
  padding-right: 15px;
  display: inline-block;

  @media (max-width: $md-max) {
    width: 100%;
    padding: 0;
    display: static;
  }

  .form-group {
    label {
      color: white;
      font-weight: bold;
    }
  }
}

.venue-sidebar {
  width: 280px;
  padding-left: 15px;
  display: inline-block;

  @media (max-width: $md-max) {
    width: 100%;
    padding: 0;
    display: static;
    padding: 15px;
  }
}


.venue-save {
  width: 265px;
  border-radius: 3px;
  background: $med-gray;
  padding: 30px;
  margin-right: 15px;

  @media (max-width: $md-max) {
    width: 100%;
    padding: 0;
    display: static;
    padding: 15px;
    margin-right: 0;
  }

  .btn {
    width: 100%;
  }
}

.photo-gallery {
  button {
    i,svg {
      margin-right: 8px;
    }
  }

  .img-thumbnail {
    background: $med-gray;
    border-color: $med-gray;
  }

  .photo {
    .close-button {
      position: absolute;
      top: 10px;
      right: 25px;
      color: white;
      background: rgba($med-gray, 0.75);
      border: none;
      border-radius: 3px;

      @media (min-width: $lg-min) {
        display: none;
      }

      i,svg {
        margin-right: 0;
      }
    }

    &:hover {
      .close-button {
        display: block;

        &:hover {
          background: $med-gray;
        }
      }
    }
  }
}

.feature-input-list {
  .input-with-close {
    input {
      width: calc(100% - 50px);
      display: inline-block;
    }

    .close-button {
      height: 45px;
      width: 45px;
      display: inline-block;
      margin-left: 5px;
      background-color: rgba(255, 255, 255, 0.1) !important;
      border: solid 1px rgba(255, 255, 255, 0.2) !important;
      color: white;
    }
  }
}

.menu-block {
  .left {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }

  .close-button {
    margin-top: 32px;
    height: 45px;
    width: 45px;
    display: inline-block;
    margin-left: 5px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: solid 1px rgba(255, 255, 255, 0.2) !important;
    color: white;
    vertical-align: top;
  }
}

.icon-right {
  margin-right: 8px;
}

.form-control-file {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  height: 45px;
  padding: 7px;
}

.bv-table {
  background: $med-gray;
  color: white;

  thead {
    tr {
      th {
        border: none;
      }
    }
  }

  tbody {
    tr {
      td {
        border: none;

        a {
          color: $green;
        }

        &.description {
          white-space: nowrap;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:nth-of-type(odd) {
        background: rgba(white, 0.1);
      }
    }
  }

  ul.action-buttons {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin-right: 5px;
      display: inline-block;

      &:last-of-type {
        margin-right: 0;
      }

      a,
      .btn {
        background-color: lighten($med-gray, 10%);
        border: solid 1px rgba(255, 255, 255, 0.2) !important;
        border-radius: 3px;
        color: rgba(white, 0.87);
        width: 60px;
        padding: 0px 0px;
        display: inline-block;
        vertical-align: top;
        height: auto;

        &:hover {
          background-color: $green;
          border-color: $green;
        }
      }

      a {
        font-weight: bold;
        line-height: 24px;
        width: 60px;
        min-height: 26px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
      }

      .dropdown-menu {
        a {
          display: block;
          background: none;
          border: none !important;
          width: 100%;
        }
      }
    }
  }
}

.pagination {
  li {
    a {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border: solid 1px rgba(255, 255, 255, 0.2) !important;
      color: rgba(white, 0.5);
      outline: 0 !important;

      &.active {
        color: $green;
        font-weight: bold;
      }

      &:hover {
        color: $green;
      }
    }
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.height-250 {
  height: 250px !important;
}

.bv-table-header {
  h2 {
    margin-top: 55px !important;
  }

  .search-area {
    margin-top: 3rem;

    @media (max-width: $sm-max) {
      margin-top: 10px;
    }

    .search {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 110px);
      margin-top: 0;
      margin-bottom: 0;
      position: relative;

      input {
        width: calc(100% - 10px);
      }

      i,svg {
        position: absolute;
        top: 15px;
        right: 25px;
      }
    }

    .export {
      vertical-align: top;
      display: inline-block;
      i,svg {
        margin-right: 7px;
      }

      .btn {
        width: 110px;
        background-color: $med-gray;
        border-color: $med-gray;
        color: rgba(white, 0.87);
        height: 45px;

        &:hover {
          color: white;
        }

        &.delete {
          &:hover {
            color: $red;
            border-color: $red;
          }
        }
      }
    }
  }
}

.admin-add-button {
  display: inline-block;

  .btn {
    margin-left: 6px;
    height: 48px;
    border-radius: 3px;

    i,svg {
      margin-right: 7px;
    }
  }
}

.user-count-button {
  font-weight: bold;
  color: $green;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.force-margin-right-2 {
  margin-right: 5px;
}

.by-input {
  height: 50px;
}

.cancel-plan {
  opacity: 0.67;

  .txt-btn {
    border: none;
    color: white;
    background: none;
    padding: 0;
    line-height: 1.5;
    font-weight: normal;
    display: inline;
    vertical-align: middle;
    /* margin-bottom: 3px; */
    text-decoration: underline;
    margin-bottom: 5px;
  }
}

.limit-me {
  max-width: 550px;
}