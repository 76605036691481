@import '../scss/constants.scss';

.login {
  height: 100vh;

  .row,
  [class*="col-"] {
    height: 100vh;
  }

  .card {
    @extend .vertical-center;

    padding: 40px;
    display: block;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    border: solid 1px rgba(white, 0.05);

    @media(min-width: $xl-min) {
      padding: 60px;
    }
  }

  .header__logo {
    margin-bottom: 20px;
  }

  &__image {
    max-width: 80px;
    margin-bottom: $gutter;
  }

  input {
    margin-bottom: 5px;
  }

  .btn--primary {
    margin: 20px 0 $gutter !important;
  }

  .btn {
    display: block;
    width: 100%;
    margin: 5px 0 $gutter !important;
  }

  .btn-link {
    margin-bottom: 0px !important;
  }
}

.social-login-button {
  margin-bottom: 15px;
}

.social-login {
  margin: 0 !important;
  margin-bottom: 4px !important;
  width: 100% !important;
  height: 56px !important;
}

.social-btn {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  padding-left: 7px;
}

.fancy {
  line-height: 1;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
}
.fancy span {
  display: inline-block;
  position: relative;  
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 0px;
  border-top: 1px solid white;
  top: 7px;
  width: 600px;
  opacity: 0.2
}
.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}
.input-wrapper {
  position: relative;
  width: 100%;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}