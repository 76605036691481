@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');
@import './animations.scss';

// Colors
$white: #fff;
$black: #000;
$light-gray: #f5f5f5;
$med-gray: #242733;
$dark-gray: #141721;
$off-white: #fafafa;
$purple: #8439dd;
$blue: #0062cc;
$green: #00bf91;
$red: #f5565b;
$orange: #eb5e55;

$bg-color: $dark-gray;
$border-color: #ddd;
$primary-color: $purple;
$secondary-color: $green;
$highlight-color: $purple;

// Fonts
$bold: 700;

// General Styles
$border-radius: 3px;
$default-border: solid 1px $border-color;
$default-font: rgba($white, 0.67);
$gutter: 30px;

// Header Styles
$header-height: 65px;
$header-padding: 10px;

// Media Queries
$xs-min: 320px;
$sm-min: 576px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xs-max: ($sm-min - 1);
$sm-max: ($md-min - 1);
$md-max: ($lg-min - 1);
$lg-max: ($xl-min - 1);

// Alignment
.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Mixins
@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default) {
  box-shadow: $x-axis $y-axis $blur $color;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin background-cover {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}