@import '../scss/constants.scss';

$hero-max: 800px;

.marketing-hero {
  @include background-cover;

  width: 100%;
  height: 100vh;
  max-height: $hero-max;
  margin: -$gutter 0 $gutter;
  text-align: center;
  background-image: url('../img/nightclub-bg.jpg');

  &__overlay {
    width: 100%;
    height: 100%;
    padding-top: calc(#{$header-height} / 2);
    background: rgba($black, 0);
  }

  .container,
  .row,
  .col-12 {
    height: 100vh;
    max-height: $hero-max;
  }

  .vertical-center {
    margin-top: -$gutter;
  }

  h1 {
    color: $white;
    font-weight: 900;
    font-size: 60px;
    text-shadow: 1px 1px 1px #000;
  }

  p {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: $gutter;
    color: rgba($white, 0.87);
    font-size: 1.1rem;
    text-shadow: 1px 1px 1px #000;
  }

  .btn {
    display: inline-block;
    width: auto;
  }

  @media (max-width: $xs-max) {
    h1 {
      font-size: 45px;
    }
  }

  &__scroll-indicator {
    position: absolute;
    bottom: 90px;
    left: calc(50% - 12px);
    color: white;
    font-size: 24px;
    -webkit-animation: ca3_fade_move_down 2s infinite;
    animation: ca3_fade_move_down 2s infinite;
  }
}

.app-store-buttons {
  a {
    display: inline-block;
    margin: 3px;
    padding: 0;
    width: 220px !important;
    text-align: left;

    @media(max-width: $xs-max) {
      display: block !important;
      margin: 5px auto;
    }

    .left {
      display: inline-block;
      vertical-align: top;
      padding: 12px 15px;
      border-right: solid 1px rgba(255,255,255,0.2);
      height: 61px;
      width: 60px;
      text-align: center;

      i,svg {
        font-size: 35px;

        &.fa-google-play {
          position: relative;
          top: 7px;
          font-size: 27px;
        }

        &.fa-apple {
          position: relative;
          top: 2px;
        }
      }
    }

    .right {
      display: inline-block;
      vertical-align: top;
      text-align: left;
      padding: 0 15px;
      line-height: 1;

      label {
        display: block;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 13px;
        opacity: 0.67;
        margin-bottom: 4px;
        margin-top: 12px;
      }

      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}