@import '../scss/constants.scss';


footer {
  background: darken($dark-gray, 5%);
  opacity: 1;
  padding: 90px 0px;

  .logo {
    display: inline-block;

    a {
      display: block;
      height: 18px;
      width: 100%;
      margin-top: 10px;
    }

    img {
      height: 35px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: block;
      margin-bottom: 10px;
      font-size: 15px;

      a,button {
        color: rgba($white, 0.8);

        &:hover {
          color: $purple;
        }
      }

      button {
        outline: 0;
        padding: 0;
        border: none;
        background: none;
      }
    }
  }

  label {
    font-weight: normal;
    margin-bottom: 15px;
    color: rgba($white, 0.4);
    margin-top: 15px;
    font-size: 15px;
  }

  .social {
    text-align: right;
    margin-top: 20px;

    li {
      @include border-radius($border-radius);

      display: inline-block;
      background: $med-gray;
      height: 45px;
      width: 45px;
      margin-right: 5px;
      text-align: center;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      i,svg {
        font-size: 20px;
        margin-top: 13px;
      }

      &:hover {
        background: $purple;

        i,svg {
          color: white;
        }
      }
    }
  }

  @media(max-width: $sm-max) {
    text-align: center;

    .social {
      text-align: center;
    }
  }

  @media(max-width: $md-max) {
    padding-bottom: 150px;
  }
}

.footer-nav {
  display: none;
  transform: translateY(100px);
  @include transition(all .4s ease);
  @media (max-width: $md-max) {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;
    height: auto;
    background: #111;
    padding-bottom: env(safe-area-inset-bottom);
    transform: translateY(0px);

    ul {
      list-style: none;
      font-size: 0px;
      width: 100%;
      display: block;
      height: 60px;
      padding-left: 0px;

      li {
        display: inline-block;
        padding-top: 2px;
        width: 20%;
        font-size: 13px;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        i,svg {
          display: block;
          font-size: 20px;
          margin: 2px 0px;
          color: rgba(255,255,255,0.9);
          height: 21px;
          margin: 0 auto;
          margin-bottom: 3px;
        }

        span {
          display: block;
          color: rgba(255,255,255,0.5);
        }

        a {
          position: relative;
          display: inline-block;
          vertical-align: top;
          &:hover,
          &:visited,
          &:active,
          &:focus {
            text-decoration: none !important;
            outline: 0;
          }
          &:active {
            i {
              color: #9a9a9a;
            }
            span {
              color: #888;
            }
          }
        }
      }
    }
  }
  &.active {
    transform: translateY(0px);
  }
}