@import '../scss/constants.scss';

.list-header {
  .rating {
    margin-left: 15px;
    display: inline-block;

    i,
    svg {
      margin-right: 3px;
    }

    .fa-star {
      color: $green;
    }

    .fa-star-o {
      color: rgba(white, 0.67);
      opacity: 0.5;
    }
  }
}

.profile-actions {
  margin-top: 45px;

  @media (min-width: $md-min) and (max-width: $md-max){
    margin-bottom: 45px;
    margin-top: -30px;
  }

  @media (min-width: $lg-min) {
    text-align: right;
  }

  .btn {
    margin-right: 5px;

    &.btn-primary {
      margin-right: 0px;

      i,svg {
        margin-right: 8px;
      }
    }

    span {
      display: inline-block;
      margin-left: 5px;
    }

    &.btn-secondary:disabled {
      opacity: 1;
      background: darken($green, 10%);
    }

    &.btn-error:disabled {
      opacity: 1;
      background: darken($red, 10%);
    }

    &.btn-primary:disabled {
      opacity: 1;
      background: darken($purple, 10%);
    }
  }
}

.profile {
  .video-area {
    @include border-radius($border-radius);

    position: relative;
    overflow:hidden;
    margin-bottom: 15px;

    .left-arrow {
      position: absolute;
      left: 0px;
      right: auto;
      top: calc(50% - 20px);
    }

    .right-arrow {
      position: absolute;
      right: 0px;
      left: auto;
      top: calc(50% - 20px);
    }

    .left-arrow,
    .right-arrow {
      .btn {
        i, svg {
          color: $white;
          font-size: 30px;
        }
      }
    }

    &:hover {
      .left-arrow,
      .right-arrow {
        .btn {
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .img-fluid {
      min-width: 100%;
    }
  }

  .lower-header {
    .btn {
      height: 50px !important;

      &.btn-error {
        width: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 10px;
        float: left;
      }

      &.btn-secondary {
        width: calc(50% - 5px);
        margin-left: 5px;
        margin-right: 0px;
        margin-bottom: 10px;
        display: inline-block;
      }

      &.btn-primary {
        margin-bottom: 45px;
      }
    }

    .username {
      margin-bottom: 15px;
    }

    .profile-actions {
      margin-top: 15px;
    }
  }

  .address {
    padding: 30px;

    a {
      color: $green;
    }

    h5 {
      margin-bottom: 15px;

      i,svg {
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }

    hr {
      border-color: rgba($white, 0.2);
    }
  }

  ul.leaders {
    max-width: 100%;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
    color: rgba($white,0.67);

    li:before {
      float: left;
      width: 0;
      white-space: nowrap;
      color: rgba($white,0.2);
      content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
    }

    span:first-child {
      padding-right: 0.33em;
      background: $med-gray;
    }

    span + span {
      float: right;
      padding-left: 0.33em;
      background: $med-gray;
    }
  }

  .card {
    &:hover {
      transform: translateY(0) !important;
    }
  }
}

hr.white {
  border-color: rgba(255,255,255,0.2);
}

.fa-margin-right {
  margin-right: 7px;
}

.share-buttons {
  button {
    border: solid 1px rgba(white, 0.2) !important;
    height: 50px;
    width: 50px;
    margin-right: 3px;
    margin-bottom: 3px;
    @include border-radius($border-radius);

    i,
    svg {
      font-size: 22px;
      position: relative;
      top: 2px;
    }

    &[aria-label="facebook"] {
      background-color: #1877f2 !important;
      border-color: #1877f2 !important;
      color: white !important;
    }

    &[aria-label="twitter"] {
      background-color: #1da1f2 !important;
      border-color: #1da1f2 !important;
      color: white !important;
    }

    &[aria-label="pinterest"] {
      background-color: #bd081c !important;
      border-color: #bd081c !important;
      color: white !important;
    }

    &[aria-label="reddit"] {
      background-color: #ff5700 !important;
      border-color: #ff5700 !important;
      color: white !important;
    }

    &[aria-label="whatsapp"] {
      background-color: #25d366 !important;
      border-color: #25d366 !important;
      color: white !important;
    }

    &:hover {
      color: white !important;

      &[aria-label="facebook"] {
        background-color: darken(#1877f2, 8%) !important;
        border-color: darken(#1877f2, 8%) !important;
      }

      &[aria-label="twitter"] {
        background-color: darken(#1da1f2, 8%) !important;
        border-color: darken(#1da1f2, 8%) !important;
      }

      &[aria-label="pinterest"] {
        background-color: darken(#bd081c, 8%) !important;
        border-color: darken(#bd081c, 8%) !important;
      }

      &[aria-label="reddit"] {
        background-color: darken(#ff5700, 8%) !important;
        border-color: darken(#ff5700, 8%) !important;
      }

      &[aria-label="whatsapp"] {
        background-color: darken(#25d366, 8%) !important;
        border-color: darken(#25d366, 8%) !important;
      }
    }
  }
}

// Comments
.comment-input-box {
  margin-bottom: 20px;

  .comment-input {
    margin-top: 20px;
    height: 150px !important;
    margin-bottom: 10px;
  }
}

.comments {
  margin-top: 40px;
  margin-bottom: 30px;

  .card.comment {
    padding: 30px;
    position: relative;
    display: block;
    margin-bottom: 10px;
    margin-bottom: 0;

    .left {
      width: 70px;
      display: inline-block;
      vertical-align: top;

      img {
        width: 50px;
        height: 50px;
        background: white;
        @include border-radius(50px);
      }
    }

    .right {
      display: inline-block;
      width: calc(100% - 70px);
      vertical-align: top;

      span {
        font-size: 13px;

        &.edit-buttons {
          float: right;
          visibility: hidden;

          button {
            border: none;
            padding: 0;
            margin: 0;
            background: none;
            font-weight: bold;

            &.edit {
              color: $green;
              margin-right: 10px;

              &:hover {
                color: darken($green, 5);
              }
            }

            &.delete {
              color: $red;

              &:hover {
                color: darken($red, 5);
              }
            }
          }

          @media (max-width: $sm-max) {
            float: none;
            display: block;
            visibility: visible;
            margin-top: 15px;
          }

          @media (max-width: $md-max) {
            visibility: visible;
          }
        }
      }
    }

    &:hover {
      .right {
        .edit-buttons {
          visibility: visible;
        }
      }
    }
  }
}

// Events
.events-container {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 10px;

    li {
      padding-bottom: 30px;

      .event {
        display: block;
        border-bottom: solid 1px rgba(white, 0.2);
        cursor: pointer;
        padding-bottom: 30px;

        .left {
          width: 200px;
          height: 200px;
          display: inline-block;
          vertical-align: top;
          background-color: lighten(#141721, 50%);
          @include background-cover;

          img {
            @include border-radius($border-radius);
          }

          @media (max-width: $sm-max) {
            height: auto;
            width: 100%;
            margin-bottom: 15px;
          }
        }

        .right {
          padding-left: 20px;
          display: inline-block;
          width: calc(100% - 200px);
          vertical-align: top;

          h6 {
            color: white;
            font-size: 18px;
            margin-bottom: 3px;
          }

          .date-time {
            font-size: 15px;
          }

          @media (max-width: $sm-max) {
            width: 100%;
            margin-bottom: 15px;
            padding-left: 0;
          }

          button {
            margin-top: 10px;
            margin-bottom: 0px;
          }
        }
      }

      &:last-of-type {
        padding-bottom: 0;

        .event {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
}

.drink-specials {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 10px;

  li {
    border-bottom: solid 1px rgba(white, 0.2);
    padding-bottom: 15px;
    margin-bottom: 15px;

    h6 {
      color: white;
    }

    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .txt-btn {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-size: 13px;

      i,svg {
        margin-right: 5px;
      }

      &.like {
        color: $green;

        &:hover {
          color: darken($green, 5%);
        }
      }

      &.dislike {
        color: $red;
        margin-right: 10px;

        &:hover {
          color: darken($red, 5%);
        }
      }

      @media (max-width: $sm-max) {
        display: inline-block;
        width: auto;
      }
    }
  }
}

.player-area {
  video {
    width: 100%;
    height: 100%;
  }
}

.carousel-control-prev, .carousel-control-next {
  bottom: 50px;
  margin-top: 50px;
}