@import '../scss/constants.scss';

.venue-register {

  min-height: 100vh;
  margin-top: 100px;

  .card {
    @extend .vertical-center;

    padding: 40px;
    display: block;
    margin: 0 auto;
    text-align: center;
    border: solid 1px rgba(white, 0.05);

    @media(min-width: $xl-min) {
      padding: 60px;
    }
  }

  .header__logo {
    margin-bottom: 20px;
  }

  &__image {
    max-width: 80px;
    margin-bottom: $gutter;
  }

  input {
    margin-bottom: 5px;
  }

  .btn--primary {
    margin: 20px 0 $gutter !important;
  }

  .btn {
    display: block;
    width: 100%;
    margin: 5px 0 $gutter !important;
  }

  .btn-link {
    margin-bottom: 0px !important;
  }

  .force-30 {
    margin-top: 45px !important;
  }
}