@import '../scss/constants.scss';

.register {
  height: 100vh;

  .row,
  [class*="col-"] {
    height: 100vh;
  }

  .card {
    @extend .vertical-center;

    padding: 40px;
    display: block;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    border: solid 1px rgba(white, 0.05);

    @media(min-width: $xl-min) {
      padding: 60px;
    }
  }

  .header__logo {
    margin-bottom: 20px;
  }

  &__image {
    max-width: 80px;
    margin-bottom: $gutter;
  }

  input {
    margin-bottom: 5px;
  }

  .btn--primary {
    margin: 20px 0 $gutter !important;
  }

  .btn {
    display: block;
    width: 100%;
    margin: 5px 0 $gutter !important;
  }

  .btn-link {
    margin-bottom: 0px !important;
  }
}
.input-wrapper {
  position: relative;
  width: 100%;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}