@import '../scss/constants.scss';

.events-page {
  .white {
    color: white !important;
  }
}

.plyr--full-ui input[type=range] {
  color: $green;
}

.plyr__control--overlaid {
  background: rgba($green, .8);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: $green;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba($green, .5);
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: $green;
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__controls button:hover, .plyr__play-large {
  background: $green;
}

.plyr__progress--played, .plyr__volume--display {
  background: $green;
}

.plyr__progress--played, .plyr__volume--display {
  color: $green;
}

.search-events {
  @media (min-width: $md-min) {
    margin-top: 30px;
  }

  #search {
    width: calc(100% - 165px);
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;

    @media (max-width: $md-max) {
      width: calc(100% - 95px);
    }
  }

  &.with-dates {
    #search {
      width: calc(100% - 450px);
      vertical-align: top;
      height: 48px;
    }

    .dropdown {
      .d-lg-inline {
        display: none !important;
      }
    }

    .dropdown-toggle {
      margin-right: 5px;
      // width: 90px;
      vertical-align: top;
      height: 48px;
    }

    .date-select {
      width: 265px;
      display: inline-block;
      vertical-align: top;
    }

    @media (max-width: $md-max) {
      #search {
        width: calc(100% - 95px);
        vertical-align: top;
      }

      .dropdown-toggle {
        margin-right: 0;
      }

      .date-select {
        width: 100%;
        margin-top: 6px;
      }
    }
  }
}

.event-date {
  clear: both;
  display: block;
  font-weight: bold;
  color: rgba(white, 0.67);
  font-size: 15px;
}