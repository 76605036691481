@import '../scss/constants.scss';

.account {
  padding-top: 100px;

  .card {
    padding: 40px;
    display: block;
    margin: 0 auto;
    border: solid 1px rgba(white, 0.05);
    margin-bottom: 30px;

    @media(min-width: $xl-min) {
      padding: 40px;
    }

    h4 {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    p {
      padding-bottom: 15px;
    }
  }

  .header__logo {
    margin-bottom: 20px;
  }

  &__image {
    max-width: 80px;
    margin-bottom: $gutter;
  }

  input {
    margin-bottom: 5px;
  }

  .btn--primary {
    margin: 20px 0 $gutter !important;
  }

  .btn {
    display: block;
    width: 100%;
    margin: 5px 0 $gutter !important;

    &.input-remove {
      margin: 0 0 0 5px !important;
    }
  }

  .btn-link {
    margin-bottom: 0px !important;
  }

  .table {
    @include border-radius($border-radius);

    overflow: hidden;
    background: rgba($white, 0.05);

    tbody {

      tr {
        border: solid 1px rgba($white, 0.2);

        td {
          text-align: left;
          color: rgba($white, 0.87);
          border-top: solid 1px rgba($white, 0.1);

          a {
            color: $green;
          }

          &:nth-of-type(2) {
            text-align: right;
          }
        }

        &:first-of-type {
          td {
            border-top: solid 1px rgba($white, 0.2);
          }
        }
      }
    }
  }

  label {
    color: rgba(white, 1);
    font-weight: bold;
  }

  .form-check {
    label {
      font-weight: normal;
      color: rgba(white, 0.87);
      margin-left: 3px;
    }
  }

  .select-ranking {
    .bv-select {
      width: 60px;
      display: inline-block;
    }

    label {
      font-weight: normal;
      color: rgba(white, 0.87);
      margin-left: 10px;
    }
  }
}

.user_avatar {
  text-align: center;

  img {
    @include border-radius($border-radius);

    overflow: hidden;
    border: solid 1px rgba(white, 0.2);
    margin-bottom: 15px;
  }
}

hr.margin {
  margin-top: 37px;
}

.profile-info-block {
  @include border-radius($border-radius);

  overflow: hidden;
  background: rgba($white, 0.05);
  padding-left: 0;
  list-style-type: none;
  border: solid 1px rgba($white, 0.2);

  li {
    padding: 12px 15px;
    border-bottom: solid 1px rgba($white, 0.2);

    b {
      display: block;
      color: rgba(white, 0.87);
    }

    a {
      color: $green;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}