@import "../scss/constants.scss";

.view-all-button {
  display: inline-block;
  margin-top: 45px;
}

.list {
  padding-top: calc(#{$header-height} + #{$gutter});

  .list-header {
    h3 {
      margin-top: 30px;
    }

    .btn-link {
      padding: 0px;
      font-weight: normal;
      margin-bottom: 45px;
      width: auto;
    }
  }
}

.search {
  @media (min-width: $md-min) {
    margin-top: 30px;
  }

  #search {
    width: calc(100% - 165px);
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;

    @media (max-width: $md-max) {
      width: calc(100% - 95px);
    }
  }

  &.with-dates {
    #search {
      width: calc(100% - 365px);
      vertical-align: top;
      height: 48px;
    }

    .dropdown {
      .d-lg-inline {
        display: none !important;
      }
    }

    .dropdown-toggle {
      margin-right: 5px;
      width: 90px;
      vertical-align: top;
      height: 48px;
    }

    .date-select {
      width: 265px;
      display: inline-block;
      vertical-align: top;
    }

    @media (max-width: $md-max) {
      #search {
        width: calc(100% - 95px);
        vertical-align: top;
      }

      .dropdown-toggle {
        margin-right: 0;
      }

      .date-select {
        width: 100%;
        margin-top: 6px;
      }
    }
  }
}

.event-date {
  clear: both;
  display: block;
  font-weight: bold;
  color: rgba(white, 0.67);
  font-size: 15px;
}

.admin-search {
  @media (min-width: $md-min) {
    margin-top: 30px;
  }

  input {
    width: calc(100% - 300px);
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;

    @media (max-width: $md-max) {
      width: calc(100% - 230px);
    }
  }

  &.condensed {
    margin-top: 0;

    .dropdown,
    input .admin-add-button {
      vertical-align: top;
    }

    .btn {
      height: 45px;
    }
  }
}

.infinite-loader-container {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
