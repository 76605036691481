@import '../scss/constants.scss';

.covid {
  padding-top: 100px;
  padding-bottom: 30px;

  .card {
    padding: 30px;

    ul {
      @include border-radius($border-radius);

      padding: 0;
      margin: 0;
      border: solid 1px rgba(255,255,255,0.2);

      li {
        border-bottom: solid 1px rgba(255,255,255,0.2);

        a {
          display: block;
          width: 100%;
          padding: 15px;
          color: rgba(255,255,255,0.87);
          background: rgba(255,255,255,0.04);
          font-weight: bold;

          i,
          svg {
            float: right;
            opacity: 0.4;
            padding-top: 4px;
          }

          &:hover {
            background: $purple;
            color: white;

            i,
            svg {
              color: white;
            }
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}