@import '../../scss/constants.scss';

.all-event-btn {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  font-weight: bold;
  color: rgba(white, 0.87);
  outline: 0 !important;
  box-shadow: none !important;

  &:hover {
    color: $green;
  }
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;

  .nav-item {
    background: none;
    border: none;
    padding: 10px 0;
    color: rgba($white, 0.67);
    margin-right: 20px;

    &.active {
      background: none;
      border: none;
      border-bottom: solid 3px $green;
      color: rgba($white, 0.67);
      color: white;
      font-weight: bold;
    }
  }
}