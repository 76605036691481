@import "../scss/constants.scss";

.marker-icon {
  font-size: 30px;
  color: $purple;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.modal-inner {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: auto;
  width: 40%;
  background: #232734;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 30px;
  overflow: hidden;
  color: white;
}

.inner-card{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 20px;
  width: 100%;
}

.white-color {
  color: white !important;
}

.venue-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.rating {
  display: inline-block;

  i,
  svg {
    margin-right: 3px;
  }

  .fa-star {
    color: $green;
  }

  .fa-star-o {
    color: rgba($green, 0.67);
    opacity: 0.5;
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
}

.map-toggle {
  margin-top: 30px;
}

.map-container {
  height: 100vh;
  width: 100%;
  position: relative;
  border: thin $purple solid;
  padding: 4px;
  border-radius: 4px;
}

.map-loader {
  min-height: 100vh;

  > div {
    top: 50%;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    margin-top: -75px;
  }
}
