@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900");
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@-moz-keyframes ca3_fade_move_down {
  0% {
    -moz-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}