@import '../scss/constants.scss';

.username {
  display: block;
  margin-bottom: 46px;
  line-height: 1;

  a {
    padding-left: 1px;
    color: rgba($white, 0.87) !important;
    opacity: 0.6;
    font-size: 15px;

    &:hover {
      color: rgba($white, 1) !important;
    }
  }
}