@import './scss/constants.scss';

// General Styles
body,
html {
  background: $dark-gray;
  color: $default-font;
  font-size: 16px;
}

.app {
  max-width: 100vw;
  overflow-x: hidden;
}

.main {
  min-height: calc(100vh - 320px);
}

// Typography
@include headings {
  color: rgba($black, 0.87);
  font-weight: 700;
}

a {
  color: $highlight-color;

  &:hover {
    color: $highlight-color;
  }
}

ol,
ul {
  padding-left: 18px;

  li {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

hr {
  margin: $gutter 0;
}

h1, h2, h3, h4, h5 {
  color: $white;
}

.main {
  padding-bottom: 90px;
}

// Buttons
.bold {
  font-weight: $bold;
}
.btn {
  display: inline-block;
  width: auto;
  border: $default-border;
  background: $white;
  font-weight: $bold;
  padding: calc(#{$gutter} / 4) calc(#{$gutter} / 1.5);
  font-size: 15px;
  line-height: 24px;

  &:hover {
    text-decoration: none;
    border-color: darken($border-color, 5%);
    background: darken($white, 5%);
  }

  &:focus {
    box-shadow: none !important;
    outline: 0;
  }

  &--primary,
  &-primary, {
    border-color: $highlight-color;
    background: $highlight-color;
    color: $white;

    &:hover,
    &:active,
    &.active {
      border-color: darken($highlight-color, 5%) !important;
      background-color: darken($highlight-color, 5%) !important;
      color: $white;
    }
  }

  &--secondary,
  &-secondary, {
    border-color: $secondary-color;
    background: $secondary-color;
    color: $white;

    &:hover,
    &:active,
    &.active {
      border-color: darken($secondary-color, 5%);
      background-color: darken($secondary-color, 5%);
      color: $white;
    }
  }

  &--link,
  &-link {
    &:hover,
    &:active,
    &.active {
      border-color: darken($secondary-color, 5%);
      background-color: darken($secondary-color, 5%);
      color: $white !important;
    }
  }

  &--large,
  &-large {
    padding: calc(#{$gutter} / 2) calc(#{$gutter} * 1.5);
  }

  &--close {
    font-size: 23px;
    border: none;
  }

  &--ghost,
  &-ghost {
    background: none;
    border-color: rgba(white, 0);
    color: rgba($white, 0.6);

    &:hover,
    &:active,
    &.active {
      background: none;
      color: white;
      border-color: rgba($white, 0.2);
    }
  }

  &--error,
  &-error {
    background: $red;
    border-color: $red;
    color: $white;

    &:hover,
    &:active,
    &.active {
      background: darken($red, 5%);
      color: white;
      border-color: darken($red, 5%);
    }
  }

  @media (max-width: $sm-max) {
    display: block;
    width: 100%;

    &--close {
      display: inline-block;
      width: auto;
    }
  }

  &--link,
  &-link {
    background: rgba($white, 0,);
    border-color: rgba($white, 0);
    color: rgba($white, 0.87);
    line-height: 1;
    opacity: 0.6;

    &:hover,
    &:active,
    &.active {
      background: none;
      border-color: rgba(white, 0);
      opacity: 1;
    }
  }
}

// Modals
.modal {
  &-dialog {
    @include border-radius($border-radius);
  }

  &-content {
    background: $dark-gray;
  }

  &-header,
  &-body,
  &-footer {
    padding: $gutter;
    border: none;

    @media (min-width: $md-min) {
      padding: calc(#{$gutter} * 2);
    }
  }

  &-header {
    .h4 {
      color: white;
      font-weight: bold;
    }

    @media (min-width: $md-min) {
      padding: calc(#{$gutter} * 2) calc(#{$gutter} * 2) $gutter;
    }
  }

  &-body {
    @media (min-width: $md-min) {
      padding: $gutter calc(#{$gutter} * 2);
    }
  }

  &-header {
    h5 {
      font-size: 1.5rem;
      margin-top: calc(#{$gutter} / 4)
    }
  }

  &-footer {
    button {
      min-width: 120px;
    }

    .btn {
      border: none;
    }
  }

  .close {
    color: white;
    text-shadow: none;
    font-size: 30px;
    position: relative;
    top: 4px;
    outline: none !important;
  }
}

.modal-backgrop {
  color: $dark-gray;

  &.show {
    opacity: 0.8;
  }
}

// Pagination
.pagination {
  .page-item {

    &.gap {
      padding: 10px;
    }

    &.active {
      .page-link {
        background-color: $highlight-color;
        border-color: $highlight-color;
      }
    }
  }
}

// Alerts
.alert {
  @include border-radius(50px);

  position: fixed;
  max-width: 800px;
  top: 80px;
  right: 30px;
  left: 30px;
  margin: calc(#{$gutter} / 2) auto;
  padding-left: $gutter;
  padding-right: $gutter;
  font-weight: 700;
  color: $white;
  background: $highlight-color;
  border-color: $highlight-color;
  z-index: 99;
  box-shadow:
    0 14px 28px rgba(0,0,0,0.25),
    0 10px 10px rgba(0,0,0,0.22);

  a {
    color: black;
  }

  &-danger {
    color: $white;
    background: $red;
    border-color: $red;

    .close {
      color: $white;
    }
  }

  &-dismissible {
    .close {
      outline: 0;
      top: -2px;
      right: 5px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// Input
.form-control {
  @include border-radius($border-radius);

  height: 45px;
  background: rgba(white, 0.1);
  border: solid 1px rgba(white, 0.2);
  color: $white;

  &:focus {
    background: rgba(white, 0.1);
    border: solid 1px $purple;
    outline: 0;
    box-shadow: none;
    color: $white;
  }
}

// Dropdown
.dropdown {
  display: inline-block;

  .dropdown-toggle {
    @include border-radius($border-radius);

    height: 45px;
    background: rgba(white, 0.1) !important;
    border: solid 1px rgba(white, 0.2);
    color: $white;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $md-max) {
      width: 90px;
    }

    &:focus,
    &:active {
      background: rgba(white, 0.1);
      border: solid 1px $purple !important;
      outline: 0;
      box-shadow: none;
      color: $white;
    }

    &::after {
      margin-left: 10px;
      opacity: 0.5;
    }
  }

  .dropdown-menu {
    &.show {
      background: $med-gray;
      border: solid 1px rgba($white, 0.1);

      a {
        color: rgba(white, 0.76);

        &:hover {
          background-color: rgba($med-gray, 1);
          opacity: 0.5;
        }
      }
    }
  }
}

// Custom Select
.bv-select {
  position: relative;

  select {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: solid 1px rgba(255, 255, 255, 0.2) !important;
    height: 48px;
    color: white;
    @include border-radius(2px);

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
      border-color: $purple !important;
    }
  }

  .fa-caret-down {
    position: absolute;
    right: 15px;
    top: 17px;
    color: white;
  }

  &.inline {
    select {
      height: 45px;
    }
  }
}

// Responsive Map Embed
.map-responsive {
  @include border-radius($border-radius);

  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  background: $med-gray;

  iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}


.site-loader {
  min-height: 80vh;

  > div {
    top: 50%;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    margin-top: -75px;
  }
}

.button-loader {
  margin: auto;
}

.input-with-remove {
  input {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }

  .btn {
    background: none;
    border: solid 1px rgba(255, 255, 255, 0.2);
    color: rgba(white,0.67);
    display: inline-block !important;
    vertical-align: top;
    width: 45px !important;
    height: 45px !important;
    text-align: center;
    padding: 0 !important;
    font-size: 20px;
    margin: 0 0 0 5px !important;

    &:hover {
      color: white;
    }
  }
}

.btn-blank {
  width: auto !important;
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: rgba(white,0.76);
  height: 45px;

  i,svg {
    margin-right: 5px;
  }
}

.offset-explainer {
  position: relative;
  top: -20px;
}

select,
.custom-select {
  option {
    color: rgba(0,0,0,0.67);
  }
}

.font-weight-normal {
  font-weight: normal !important;
}

.billing-alert {
  max-width: none;
  position: static;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  padding: 40px 0;
  margin-bottom: 40px;
  font-weight: normal;

  a {
    color: white;
    text-decoration: underline;
  }
}

textarea {
  resize: none;
}