@import '../scss/constants.scss';

.specials-list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;

  li {
    @include border-radius($border-radius);

    background: $med-gray;
    overflow: hidden;
    margin: 10px 0;

    img {
      width: 205px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: $md-max) {
        display: none;
      }
    }

    .background-image {
      width: 205px;
      display: inline-block;
      vertical-align: top;
      min-height: 155px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      @media (max-width: $md-max) {
        display: none;
      }
    }

    a {
      .right {
        padding: 30px;
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 445px);

        h4 {
          font-weight: normal;
          margin-bottom: 5px;
          font-size: 22px;
        }

        p {
          color: rgba($white, 0.6);
          margin-bottom: 15px;
        }

        .rating {
          display: inline-block;

          i,
          svg {
            margin-right: 3px;
          }

          .fa-star {
            color: $green;
          }

          .fa-star-o {
            color: rgba(white, 0.67);
            opacity: 0.5;
          }
        }

        @media (max-width: $md-max) {
          width: calc(100% - 240px);
        }

        @media (max-width: $sm-max) {
          width: 100%;
        }
      }

      .buttons {
        display: inline-block;
        width: 240px;
        vertical-align: top;
        text-align: right;
        padding-top: 25px;
        padding-right: 15px;

        button {
          width: 110px;

          &.btn-error {
            margin-right: 5px;
          }
        }

        i,
        svg {
          margin-right: 5px;
        }

        @media (max-width: $sm-max) {
          width: 100%;
          padding: 0 20px 20px;

          button {
            width: calc(50% - 5px) !important;
            display: inline-block;
          }
        }
      }
    }
  }
}