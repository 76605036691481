@import '../scss/constants.scss';

.view-all-button {
  display: inline-block;
  margin-top: 45px;
}

.featured-header {
  h3 {
    margin-top: 30px;
  }

  .btn-link {
    padding: 0px;
    font-weight: normal;
    margin-bottom: 45px;
  }
}