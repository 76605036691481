@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-results {
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 60px 15px;
  border-radius: 2px;
  width: 100%;
  text-align: center;
}
.no-results p {
  margin-bottom: 0;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

body,
html {
  background: #141721;
  color: rgba(255, 255, 255, 0.67);
  font-size: 16px;
}

.app {
  max-width: 100vw;
  overflow-x: hidden;
}

.main {
  min-height: calc(100vh - 320px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

a {
  color: #8439dd;
}
a:hover {
  color: #8439dd;
}

ol,
ul {
  padding-left: 18px;
}
ol li a:hover,
ul li a:hover {
  text-decoration: none;
}

hr {
  margin: 30px 0;
}

h1, h2, h3, h4, h5 {
  color: #fff;
}

.main {
  padding-bottom: 90px;
}

.bold {
  font-weight: 700;
}

.btn {
  display: inline-block;
  width: auto;
  border: solid 1px #ddd;
  background: #fff;
  font-weight: 700;
  padding: calc(30px / 4) calc(30px / 1.5);
  font-size: 15px;
  line-height: 24px;
}
.btn:hover {
  text-decoration: none;
  border-color: #d0d0d0;
  background: #f2f2f2;
}
.btn:focus {
  box-shadow: none !important;
  outline: 0;
}
.btn--primary, .btn-primary {
  border-color: #8439dd;
  background: #8439dd;
  color: #fff;
}
.btn--primary:hover, .btn--primary:active, .btn--primary.active, .btn-primary:hover, .btn-primary:active, .btn-primary.active {
  border-color: #7725d7 !important;
  background-color: #7725d7 !important;
  color: #fff;
}
.btn--secondary, .btn-secondary {
  border-color: #00bf91;
  background: #00bf91;
  color: #fff;
}
.btn--secondary:hover, .btn--secondary:active, .btn--secondary.active, .btn-secondary:hover, .btn-secondary:active, .btn-secondary.active {
  border-color: #00a67e;
  background-color: #00a67e;
  color: #fff;
}
.btn--link:hover, .btn--link:active, .btn--link.active, .btn-link:hover, .btn-link:active, .btn-link.active {
  border-color: #00a67e;
  background-color: #00a67e;
  color: #fff !important;
}
.btn--large, .btn-large {
  padding: calc(30px / 2) calc(30px * 1.5);
}
.btn--close {
  font-size: 23px;
  border: none;
}
.btn--ghost, .btn-ghost {
  background: none;
  border-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.6);
}
.btn--ghost:hover, .btn--ghost:active, .btn--ghost.active, .btn-ghost:hover, .btn-ghost:active, .btn-ghost.active {
  background: none;
  color: white;
  border-color: rgba(255, 255, 255, 0.2);
}
.btn--error, .btn-error {
  background: #f5565b;
  border-color: #f5565b;
  color: #fff;
}
.btn--error:hover, .btn--error:active, .btn--error.active, .btn-error:hover, .btn-error:active, .btn-error.active {
  background: #f43e44;
  color: white;
  border-color: #f43e44;
}
@media (max-width: 767px) {
  .btn {
    display: block;
    width: 100%;
  }
  .btn--close {
    display: inline-block;
    width: auto;
  }
}
.btn--link, .btn-link {
  background: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.87);
  line-height: 1;
  opacity: 0.6;
}
.btn--link:hover, .btn--link:active, .btn--link.active, .btn-link:hover, .btn-link:active, .btn-link.active {
  background: none;
  border-color: rgba(255, 255, 255, 0);
  opacity: 1;
}

.modal-dialog {
  border-radius: 3px;
}
.modal-content {
  background: #141721;
}
.modal-header, .modal-body, .modal-footer {
  padding: 30px;
  border: none;
}
@media (min-width: 768px) {
  .modal-header, .modal-body, .modal-footer {
    padding: calc(30px * 2);
  }
}
.modal-header .h4 {
  color: white;
  font-weight: bold;
}
@media (min-width: 768px) {
  .modal-header {
    padding: calc(30px * 2) calc(30px * 2) 30px;
  }
}
@media (min-width: 768px) {
  .modal-body {
    padding: 30px calc(30px * 2);
  }
}
.modal-header h5 {
  font-size: 1.5rem;
  margin-top: calc(30px / 4);
}
.modal-footer button {
  min-width: 120px;
}
.modal-footer .btn {
  border: none;
}
.modal .close {
  color: white;
  text-shadow: none;
  font-size: 30px;
  position: relative;
  top: 4px;
  outline: none !important;
}

.modal-backgrop {
  color: #141721;
}
.modal-backgrop.show {
  opacity: 0.8;
}

.pagination .page-item.gap {
  padding: 10px;
}
.pagination .page-item.active .page-link {
  background-color: #8439dd;
  border-color: #8439dd;
}

.alert {
  border-radius: 50px;
  position: fixed;
  max-width: 800px;
  top: 80px;
  right: 30px;
  left: 30px;
  margin: calc(30px / 2) auto;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 700;
  color: #fff;
  background: #8439dd;
  border-color: #8439dd;
  z-index: 99;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.alert a {
  color: black;
}
.alert-danger {
  color: #fff;
  background: #f5565b;
  border-color: #f5565b;
}
.alert-danger .close {
  color: #fff;
}
.alert-dismissible .close {
  outline: 0;
  top: -2px;
  right: 5px;
  opacity: 0.8;
}
.alert-dismissible .close:hover {
  opacity: 1;
}

.form-control {
  border-radius: 3px;
  height: 45px;
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: #fff;
}
.form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px #8439dd;
  outline: 0;
  box-shadow: none;
  color: #fff;
}

.dropdown {
  display: inline-block;
}
.dropdown .dropdown-toggle {
  border-radius: 3px;
  height: 45px;
  background: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: #fff;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 991px) {
  .dropdown .dropdown-toggle {
    width: 90px;
  }
}
.dropdown .dropdown-toggle:focus, .dropdown .dropdown-toggle:active {
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px #8439dd !important;
  outline: 0;
  box-shadow: none;
  color: #fff;
}
.dropdown .dropdown-toggle::after {
  margin-left: 10px;
  opacity: 0.5;
}
.dropdown .dropdown-menu.show {
  background: #242733;
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.dropdown .dropdown-menu.show a {
  color: rgba(255, 255, 255, 0.76);
}
.dropdown .dropdown-menu.show a:hover {
  background-color: #242733;
  opacity: 0.5;
}

.bv-select {
  position: relative;
}
.bv-select select {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  height: 48px;
  color: white;
  border-radius: 2px;
}
.bv-select select:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border-color: #8439dd !important;
}
.bv-select .fa-caret-down {
  position: absolute;
  right: 15px;
  top: 17px;
  color: white;
}
.bv-select.inline select {
  height: 45px;
}

.map-responsive {
  border-radius: 3px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  background: #242733;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.site-loader {
  min-height: 80vh;
}
.site-loader > div {
  top: 50%;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  margin-top: -75px;
}

.button-loader {
  margin: auto;
}

.input-with-remove input {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
}
.input-with-remove .btn {
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.67);
  display: inline-block !important;
  vertical-align: top;
  width: 45px !important;
  height: 45px !important;
  text-align: center;
  padding: 0 !important;
  font-size: 20px;
  margin: 0 0 0 5px !important;
}
.input-with-remove .btn:hover {
  color: white;
}

.btn-blank {
  width: auto !important;
  background: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.76);
  height: 45px;
}
.btn-blank i, .btn-blank svg {
  margin-right: 5px;
}

.offset-explainer {
  position: relative;
  top: -20px;
}

select option,
.custom-select option {
  color: rgba(0, 0, 0, 0.67);
}

.font-weight-normal {
  font-weight: normal !important;
}

.billing-alert {
  max-width: none;
  position: static;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  padding: 40px 0;
  margin-bottom: 40px;
  font-weight: normal;
}
.billing-alert a {
  color: white;
  text-decoration: underline;
}

textarea {
  resize: none;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.header {
  transition: background ease 300ms, border-color ease 300ms;
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 65px;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: solid 1px rgba(221, 221, 221, 0);
  border-color: rgba(221, 221, 221, 0);
}
.header__logo {
  transition: color ease 300ms;
  display: inline-block;
  height: calc(65px - 10px * 2);
  line-height: calc(65px - 10px * 2);
  vertical-align: top;
  margin-right: calc(30px * 1.25);
}
.header__logo a {
  text-decoration: none;
  color: #fff;
  font-weight: 900;
  font-size: 20px;
  position: relative;
  top: -3px;
  left: -5px;
}
.header__logo a img {
  height: 45px;
}
.header__logo.remove-pad {
  margin-right: 0;
  margin-bottom: 0 !important;
}
.header__logo.mb-force {
  margin-bottom: 15px !important;
}
.header__nav {
  display: inline-block;
  vertical-align: top;
}
.header__nav a {
  display: inline-block;
  margin-right: 30px;
  line-height: calc(65px - 10px * 2);
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
  opacity: 1;
}
.header__nav a:hover {
  opacity: 0.7;
}
.header__nav a.active {
  color: #8439dd;
  cursor: pointer;
}
.header__nav nav {
  display: inline-block;
}
.fa-bell:before {
  font-size: 18px;
}

.map-toggle-btn {
  background: transparent;
  border: none;
  top: 0px;
  cursor: pointer;
  margin-right: 15px;
}

.map-toggle-btn:hover {
  background: transparent;
  color: #8439dd;
}

.header-active, .header.active, .header-wrapper:not(.header-wrapper--) .header {
  background: #0a0c11;
  border-color: #0a0c11;
}
.header-active .header__logo a, .header.active .header__logo a, .header-wrapper:not(.header-wrapper--) .header .header__logo a {
  color: white;
}
.header-active .header__logo a i, .header.active .header__logo a i, .header-wrapper:not(.header-wrapper--) .header .header__logo a i, .header-active .header__logo a svg, .header.active .header__logo a svg, .header-wrapper:not(.header-wrapper--) .header .header__logo a svg {
  color: #0062cc;
}
.header-active .header__nav a, .header.active .header__nav a, .header-wrapper:not(.header-wrapper--) .header .header__nav a {
  color: rgba(255, 255, 255, 0.76);
}
.header-active .btn-link, .header.active .btn-link, .header-wrapper:not(.header-wrapper--) .header .btn-link {
  color: rgba(255, 255, 255, 0.76);
}

ul.header-buttons {
  margin-bottom: 0;
  list-style-type: none;
}
ul.header-buttons li {
  display: inline-block;
  line-height: calc(65px - 10px * 2);
  vertical-align: top;
}
ul.header-buttons li:first-of-type {
  margin-right: 5px;
}
ul.header-buttons li .btn {
  display: inline-block;
  position: relative;
  top: -2px;
}

.brandmark {
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  background: #8439dd;
  height: 26px;
  width: 26px;
  margin-top: 9px;
  margin-right: 13px;
  text-align: center;
}
.brandmark i, .brandmark svg {
  color: white !important;
  font-size: 12px;
  vertical-align: top;
  position: relative;
  top: 7px;
  left: 0px;
  color: rgba(255, 255, 255, 0.67);
}
@media (max-width: 767px) {
  .brandmark {
    border-radius: 7px;
    height: 24px;
    width: 24px;
    margin-top: 10px;
  }
  .brandmark i, .brandmark svg {
    font-size: 10px;
    top: 7px;
  }
}

@media (max-width: 575px) {
  .logo-container .header__logo {
    margin-right: 0px;
  }
  .back-button {
    color: rgba(255, 255, 255, 0.87);
    font-size: 20px;
    line-height: 65px;
    position: relative;
    top: -10px;
  }
}
.header-wrapper-- .back-button i,
.header-wrapper-- .back-button svg {
  display: none;
}

.notification-icon {
  display: flex;
  margin-top: 4px;
  padding-left: 100px;
}

.user-menu {
  white-space: nowrap;
}
.user-menu ul {
  margin-bottom: 0;
  list-style-type: none;
}
.user-menu ul li {
  display: inline-block;
  line-height: calc(65px - 10px * 2);
  vertical-align: top;
  position: relative;
}
.user-menu ul li:first-of-type {
  margin-right: 5px;
}
.user-menu ul li .btn {
  display: inline-block;
  position: relative;
  top: -2px;
}
.user-menu ul li .dropdown-menu {
  display: none;
  margin-top: 0;
}
.user-menu ul li .dropdown-menu.notification-dropdown {
  display: block;
  position: absolute;
  right: 0px;
  left: auto;
  background: #242733;
}
.user-menu ul li:hover {
  cursor: default;
}
.user-menu ul li:hover .dropdown-menu {
  display: block;
  position: absolute;
  right: 0px;
  left: auto;
  background: #242733;
}
.user-menu ul li.notification-button {
  margin-right: 20px;
  margin-top: 7px;
}
.user-menu ul li.notification-button i,
.user-menu ul li.notification-button svg {
  font-size: 33px;
}
.user-menu ul li.notification-button:hover i,
.user-menu ul li.notification-button:hover svg {
  cursor: pointer;
  color: white;
}
.user-menu ul li.notification-button .count {
  border-radius: 10px;
  position: absolute;
  top: -2px;
  right: -7px;
  background: #f5565b;
  padding: 4px 8px 4px 8px;
  color: white;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
}
.user-menu ul li.notification-button .dropdown-menu {
  width: 400px;
  max-width: 100vw;
  padding: 0px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
}
.user-menu ul li.user-button {
  line-height: 65px;
  height: 65px;
  position: relative;
  top: -10px;
}
.user-menu ul li.user-button img {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin-right: 12px;
  border: solid 1px rgba(255, 255, 255, 0.67);
  position: relative;
  top: -1px;
}
.user-menu ul li.user-button .fa-caret-down {
  margin-left: 6px;
}
.user-menu ul li.user-button .dropdown-menu {
  text-align: right;
  padding: 0px;
}
.user-menu ul li.user-button .dropdown-menu a {
  color: rgba(255, 255, 255, 0.87);
  padding-right: 15px;
  padding-left: 15px;
}
.user-menu ul li.user-button .dropdown-menu a:hover {
  opacity: 0.5;
}
.user-menu ul li.user-button .dropdown-menu.user-dropdown li {
  display: block;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .user-menu {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .authorized .action-buttons {
    display: none !important;
  }
  .authorized .user-menu {
    display: inline !important;
  }
}

.notifications-mobile-link {
  height: 45px;
  width: 45px;
  display: inline-block;
  font-size: 22px;
  padding: 6px;
  position: relative;
}
.notifications-mobile-link .count {
  border-radius: 10px;
  position: absolute;
  top: -2px;
  right: -7px;
  background: #f5565b;
  padding: 4px 8px 4px 8px;
  color: white;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
}
.notifications-mobile-link i, .notifications-mobile-link svg {
  color: rgba(255, 255, 255, 0.67);
}

.authToggle {
  background: none;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.76);
  position: relative;
  left: -6px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.main--notifications {
  padding-top: calc(65px + 30px);
}

.notifications {
  padding: 25px;
}
.notifications .notification-list {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
}
.notifications .notification-list li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  line-height: 1.5;
  padding: 15px 0px;
}
.notifications .notification-list li b {
  color: white;
}
.notifications .notification-list li p {
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.5;
  margin-bottom: 0px;
}
.notifications .notification-list li small {
  color: rgba(255, 255, 255, 0.3);
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.covid-alert {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  text-align: center;
  max-width: 900px;
}
.covid-alert a {
  color: white;
  text-decoration: underline;
}
@media (max-width: 575px) {
  .covid-alert {
    top: 60px;
    width: 100%;
    max-width: none;
    margin: 0;
    border-radius: 0;
    left: 0;
  }
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.marketing-hero {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-height: 800px;
  margin: -30px 0 30px;
  text-align: center;
  background-image: url(/static/media/nightclub-bg.81480ae4.jpg);
}
.marketing-hero__overlay {
  width: 100%;
  height: 100%;
  padding-top: calc(65px / 2);
  background: rgba(0, 0, 0, 0);
}
.marketing-hero .container,
.marketing-hero .row,
.marketing-hero .col-12 {
  height: 100vh;
  max-height: 800px;
}
.marketing-hero .vertical-center {
  margin-top: -30px;
}
.marketing-hero h1 {
  color: #fff;
  font-weight: 900;
  font-size: 60px;
  text-shadow: 1px 1px 1px #000;
}
.marketing-hero p {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 1.1rem;
  text-shadow: 1px 1px 1px #000;
}
.marketing-hero .btn {
  display: inline-block;
  width: auto;
}
@media (max-width: 575px) {
  .marketing-hero h1 {
    font-size: 45px;
  }
}
.marketing-hero__scroll-indicator {
  position: absolute;
  bottom: 90px;
  left: calc(50% - 12px);
  color: white;
  font-size: 24px;
  -webkit-animation: ca3_fade_move_down 2s infinite;
  animation: ca3_fade_move_down 2s infinite;
}

.app-store-buttons a {
  display: inline-block;
  margin: 3px;
  padding: 0;
  width: 220px !important;
  text-align: left;
}
@media (max-width: 575px) {
  .app-store-buttons a {
    display: block !important;
    margin: 5px auto;
  }
}
.app-store-buttons a .left {
  display: inline-block;
  vertical-align: top;
  padding: 12px 15px;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  height: 61px;
  width: 60px;
  text-align: center;
}
.app-store-buttons a .left i, .app-store-buttons a .left svg {
  font-size: 35px;
}
.app-store-buttons a .left i.fa-google-play, .app-store-buttons a .left svg.fa-google-play {
  position: relative;
  top: 7px;
  font-size: 27px;
}
.app-store-buttons a .left i.fa-apple, .app-store-buttons a .left svg.fa-apple {
  position: relative;
  top: 2px;
}
.app-store-buttons a .right {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding: 0 15px;
  line-height: 1;
}
.app-store-buttons a .right label {
  display: block;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 13px;
  opacity: 0.67;
  margin-bottom: 4px;
  margin-top: 12px;
}
.app-store-buttons a .right span {
  font-size: 20px;
  font-weight: bold;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.view-all-button {
  display: inline-block;
  margin-top: 45px;
}

.featured-header h3 {
  margin-top: 30px;
}
.featured-header .btn-link {
  padding: 0px;
  font-weight: normal;
  margin-bottom: 45px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.card {
  border-radius: 3px;
  margin: 15px 0px;
  background: #242733;
  overflow: hidden;
  transition: transform 300ms ease;
  transform: translateY(0px);
}
.card:hover {
  transform: translateY(-4px);
  text-decoration: none !important;
}
.card .upper {
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .card .upper {
    max-height: 200px;
  }
}
.card .upper .tags {
  top: 30px;
  right: 0px;
  position: absolute;
  color: white;
  text-align: right;
}
.card .upper .tags .featured {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: bold;
  padding: 6px 15px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.card .upper .tags .featured i,
.card .upper .tags .featured svg {
  margin-left: 6px;
}
.card .upper .img-fluid {
  min-height: 200px;
  min-width: 100%;
}
.card .lower {
  padding: 20px;
  color: rgba(255, 255, 255, 0.67) !important;
}
.card .lower b {
  display: block;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .lower p {
  margin-bottom: 0px;
}
.card .lower .rating {
  display: inline-block;
}
.card .lower .rating i,
.card .lower .rating svg {
  margin-right: 3px;
}
.card .lower .rating .fa-star {
  color: #00bf91;
}
.card .lower .rating .fa-star-o {
  color: rgba(255, 255, 255, 0.67);
  opacity: 0.5;
}
.card .lower .left {
  display: inline-block;
  float: left;
}
.card .lower .right {
  display: inline-block;
  float: right;
}
.card .lower .right i,
.card .lower .right svg {
  color: #f5565b;
  margin-left: 5px;
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
}
.card.no-hover:hover {
  transform: translateY(0px);
  text-decoration: none !important;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
a:hover {
  text-decoration: none !important;
}
a:hover .card {
  text-decoration: none !important;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.pac-container {
  z-index: 9999;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center, .login .card {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.login {
  height: 100vh;
}
.login .row,
.login [class*=col-] {
  height: 100vh;
}
.login .card {
  padding: 40px;
  display: block;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  border: solid 1px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1200px) {
  .login .card {
    padding: 60px;
  }
}
.login .header__logo {
  margin-bottom: 20px;
}
.login__image {
  max-width: 80px;
  margin-bottom: 30px;
}
.login input {
  margin-bottom: 5px;
}
.login .btn--primary {
  margin: 20px 0 30px !important;
}
.login .btn {
  display: block;
  width: 100%;
  margin: 5px 0 30px !important;
}
.login .btn-link {
  margin-bottom: 0px !important;
}

.social-login-button {
  margin-bottom: 15px;
}

.social-login {
  margin: 0 !important;
  margin-bottom: 4px !important;
  width: 100% !important;
  height: 56px !important;
}

.social-btn {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  padding-left: 7px;
}

.fancy {
  line-height: 1;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
}

.fancy span {
  display: inline-block;
  position: relative;
}

.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 0px;
  border-top: 1px solid white;
  top: 7px;
  width: 600px;
  opacity: 0.2;
}

.fancy span:before {
  right: 100%;
  margin-right: 15px;
}

.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.newsletter .card {
  max-width: none;
}

.newsletter .card {
  padding: 25px;
}

.newsletter ul {
  list-style: none;
}

.newsletter .btn {
  margin: 20px 0 30px !important;
}
.about {
  padding-top: 100px;
}
.about .card {
  padding: 45px;
}
.about .white-b strong {
  color: white;
}
.about .old-logo img {
  margin-top: 15px;
  max-width: 150px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.account {
  padding-top: 100px;
}
.account .card {
  padding: 40px;
  display: block;
  margin: 0 auto;
  border: solid 1px rgba(255, 255, 255, 0.05);
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .account .card {
    padding: 40px;
  }
}
.account .card h4 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.account .card p {
  padding-bottom: 15px;
}
.account .header__logo {
  margin-bottom: 20px;
}
.account__image {
  max-width: 80px;
  margin-bottom: 30px;
}
.account input {
  margin-bottom: 5px;
}
.account .btn--primary {
  margin: 20px 0 30px !important;
}
.account .btn {
  display: block;
  width: 100%;
  margin: 5px 0 30px !important;
}
.account .btn.input-remove {
  margin: 0 0 0 5px !important;
}
.account .btn-link {
  margin-bottom: 0px !important;
}
.account .table {
  border-radius: 3px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
}
.account .table tbody tr {
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.account .table tbody tr td {
  text-align: left;
  color: rgba(255, 255, 255, 0.87);
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
.account .table tbody tr td a {
  color: #00bf91;
}
.account .table tbody tr td:nth-of-type(2) {
  text-align: right;
}
.account .table tbody tr:first-of-type td {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}
.account label {
  color: white;
  font-weight: bold;
}
.account .form-check label {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.87);
  margin-left: 3px;
}
.account .select-ranking .bv-select {
  width: 60px;
  display: inline-block;
}
.account .select-ranking label {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.87);
  margin-left: 10px;
}

.user_avatar {
  text-align: center;
}
.user_avatar img {
  border-radius: 3px;
  overflow: hidden;
  border: solid 1px rgba(255, 255, 255, 0.2);
  margin-bottom: 15px;
}

hr.margin {
  margin-top: 37px;
}

.profile-info-block {
  border-radius: 3px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  padding-left: 0;
  list-style-type: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.profile-info-block li {
  padding: 12px 15px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.profile-info-block li b {
  display: block;
  color: rgba(255, 255, 255, 0.87);
}
.profile-info-block li a {
  color: #00bf91;
}
.profile-info-block li:last-of-type {
  border-bottom: none;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.admin {
  padding-top: 100px;
}
.admin hr {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.admin-sidebar {
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding-right: 15px;
}
@media (max-width: 991px) {
  .admin-sidebar {
    width: 100%;
    padding-right: 0;
  }
}
.admin-sidebar nav {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 30px;
}
.admin-sidebar nav a {
  display: block;
  background: #242733;
  padding: 15px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border-left: solid 4px #242733;
  position: relative;
}
.admin-sidebar nav a .fa-chevron-right {
  position: absolute;
  right: 15px;
  top: 20px;
  opacity: 0;
}
.admin-sidebar nav a:hover {
  color: rgba(255, 255, 255, 0.9);
  border-left: solid 4px rgba(255, 255, 255, 0.2);
}
.admin-sidebar nav a:hover .fa-chevron-right {
  opacity: 0.5;
}
.admin-sidebar nav a:last-of-type {
  border-bottom: none;
}
.admin-sidebar nav a.active {
  color: white;
  font-weight: bold;
  border-left: solid 4px #8439dd;
  cursor: default;
}
.admin-sidebar nav a.active .fa-chevron-right {
  opacity: 1;
}

.admin-content {
  display: inline-block;
  width: calc(100% - 300px);
  vertical-align: top;
  padding-left: 15px;
}
.admin-content h2 {
  font-size: 22px;
  margin-bottom: 3px;
}
.admin-content .admin-header p span.venue {
  padding-right: 8px;
}
.admin-content .rating {
  display: inline-block;
}
.admin-content .rating i,
.admin-content .rating svg {
  margin-right: 3px;
}
.admin-content .rating .fa-star {
  color: #00bf91;
}
.admin-content .rating .fa-star-o {
  color: rgba(255, 255, 255, 0.67);
  opacity: 0.5;
}
@media (max-width: 991px) {
  .admin-content {
    width: 100%;
    padding-left: 0;
  }
}
.admin-content .venue-select {
  display: inline-block;
  width: 265px;
}
@media (max-width: 991px) {
  .admin-content .venue-select {
    width: 100%;
    padding-left: 0;
  }
}
.admin-content .date-select {
  display: inline-block;
  width: 280px;
  padding-left: 15px;
}
@media (max-width: 991px) {
  .admin-content .date-select {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .DateRangePicker {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.chart-card {
  padding: 60px;
}
@media (max-width: 991px) {
  .chart-card {
    padding: 30px;
  }
}

.DateRangePickerInput {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
}
@media (max-width: 991px) {
  .DateRangePickerInput {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}
.DateRangePickerInput .DateInput {
  background: none !important;
  width: 105px;
}
.DateRangePickerInput .DateInput input {
  background: none !important;
  color: white !important;
  font-size: 16px;
  font-weight: normal;
}
.DateRangePickerInput .DateRangePickerInput_arrow_svg {
  fill: #bbb;
}
.DateRangePickerInput .DateRangePickerInput_calendarIcon_svg {
  fill: #bbb;
  position: relative;
  top: -1px;
}
.DateRangePickerInput .DateRangePickerInput_calendarIcon {
  width: auto;
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
}
.DateRangePickerInput .DateInput_input__focused {
  border-bottom: solid 2px #8439dd !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.stat-card {
  padding: 30px;
}
.stat-card label {
  margin-bottom: 0px;
  padding-bottom: 5px;
}
.stat-card span {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
}
.stat-card .green {
  color: #00bf91;
}
.stat-card .purple {
  color: #8439dd;
}
.stat-card .red {
  color: #f5565b;
}

.venue-content {
  width: calc(100% - 295px);
  padding-right: 15px;
  display: inline-block;
}
@media (max-width: 991px) {
  .venue-content {
    width: 100%;
    padding: 0;
    display: static;
  }
}
.venue-content .form-group label {
  color: white;
  font-weight: bold;
}

.venue-sidebar {
  width: 280px;
  padding-left: 15px;
  display: inline-block;
}
@media (max-width: 991px) {
  .venue-sidebar {
    width: 100%;
    padding: 0;
    display: static;
    padding: 15px;
  }
}

.venue-save {
  width: 265px;
  border-radius: 3px;
  background: #242733;
  padding: 30px;
  margin-right: 15px;
}
@media (max-width: 991px) {
  .venue-save {
    width: 100%;
    padding: 0;
    display: static;
    padding: 15px;
    margin-right: 0;
  }
}
.venue-save .btn {
  width: 100%;
}

.photo-gallery button i, .photo-gallery button svg {
  margin-right: 8px;
}
.photo-gallery .img-thumbnail {
  background: #242733;
  border-color: #242733;
}
.photo-gallery .photo .close-button {
  position: absolute;
  top: 10px;
  right: 25px;
  color: white;
  background: rgba(36, 39, 51, 0.75);
  border: none;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .photo-gallery .photo .close-button {
    display: none;
  }
}
.photo-gallery .photo .close-button i, .photo-gallery .photo .close-button svg {
  margin-right: 0;
}
.photo-gallery .photo:hover .close-button {
  display: block;
}
.photo-gallery .photo:hover .close-button:hover {
  background: #242733;
}

.feature-input-list .input-with-close input {
  width: calc(100% - 50px);
  display: inline-block;
}
.feature-input-list .input-with-close .close-button {
  height: 45px;
  width: 45px;
  display: inline-block;
  margin-left: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  color: white;
}

.menu-block .left {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
}
.menu-block .close-button {
  margin-top: 32px;
  height: 45px;
  width: 45px;
  display: inline-block;
  margin-left: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  color: white;
  vertical-align: top;
}

.icon-right {
  margin-right: 8px;
}

.form-control-file {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  height: 45px;
  padding: 7px;
}

.bv-table {
  background: #242733;
  color: white;
}
.bv-table thead tr th {
  border: none;
}
.bv-table tbody tr td {
  border: none;
}
.bv-table tbody tr td a {
  color: #00bf91;
}
.bv-table tbody tr td.description {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bv-table tbody tr:nth-of-type(odd) {
  background: rgba(255, 255, 255, 0.1);
}
.bv-table ul.action-buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.bv-table ul.action-buttons li {
  margin-right: 5px;
  display: inline-block;
}
.bv-table ul.action-buttons li:last-of-type {
  margin-right: 0;
}
.bv-table ul.action-buttons li a,
.bv-table ul.action-buttons li .btn {
  background-color: #393e51;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.87);
  width: 60px;
  padding: 0px 0px;
  display: inline-block;
  vertical-align: top;
  height: auto;
}
.bv-table ul.action-buttons li a:hover,
.bv-table ul.action-buttons li .btn:hover {
  background-color: #00bf91;
  border-color: #00bf91;
}
.bv-table ul.action-buttons li a {
  font-weight: bold;
  line-height: 24px;
  width: 60px;
  min-height: 26px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.bv-table ul.action-buttons li .dropdown-menu a {
  display: block;
  background: none;
  border: none !important;
  width: 100%;
}

.pagination li a {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  color: rgba(255, 255, 255, 0.5);
  outline: 0 !important;
}
.pagination li a.active {
  color: #00bf91;
  font-weight: bold;
}
.pagination li a:hover {
  color: #00bf91;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.height-250 {
  height: 250px !important;
}

.bv-table-header h2 {
  margin-top: 55px !important;
}
.bv-table-header .search-area {
  margin-top: 3rem;
}
@media (max-width: 767px) {
  .bv-table-header .search-area {
    margin-top: 10px;
  }
}
.bv-table-header .search-area .search {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 110px);
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}
.bv-table-header .search-area .search input {
  width: calc(100% - 10px);
}
.bv-table-header .search-area .search i, .bv-table-header .search-area .search svg {
  position: absolute;
  top: 15px;
  right: 25px;
}
.bv-table-header .search-area .export {
  vertical-align: top;
  display: inline-block;
}
.bv-table-header .search-area .export i, .bv-table-header .search-area .export svg {
  margin-right: 7px;
}
.bv-table-header .search-area .export .btn {
  width: 110px;
  background-color: #242733;
  border-color: #242733;
  color: rgba(255, 255, 255, 0.87);
  height: 45px;
}
.bv-table-header .search-area .export .btn:hover {
  color: white;
}
.bv-table-header .search-area .export .btn.delete:hover {
  color: #f5565b;
  border-color: #f5565b;
}

.admin-add-button {
  display: inline-block;
}
.admin-add-button .btn {
  margin-left: 6px;
  height: 48px;
  border-radius: 3px;
}
.admin-add-button .btn i, .admin-add-button .btn svg {
  margin-right: 7px;
}

.user-count-button {
  font-weight: bold;
  color: #00bf91;
}
.user-count-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.force-margin-right-2 {
  margin-right: 5px;
}

.by-input {
  height: 50px;
}

.cancel-plan {
  opacity: 0.67;
}
.cancel-plan .txt-btn {
  border: none;
  color: white;
  background: none;
  padding: 0;
  line-height: 1.5;
  font-weight: normal;
  display: inline;
  vertical-align: middle;
  /* margin-bottom: 3px; */
  text-decoration: underline;
  margin-bottom: 5px;
}

.limit-me {
  max-width: 550px;
}
.AdminChart_graphContainer__KSebX {
    height: 450px;
}
.privacy {
  padding-top: 100px;
}
.privacy .card {
  padding: 45px;
}
.privacy .white-b strong {
  color: white;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.list-header .rating {
  margin-left: 15px;
  display: inline-block;
}
.list-header .rating i,
.list-header .rating svg {
  margin-right: 3px;
}
.list-header .rating .fa-star {
  color: #00bf91;
}
.list-header .rating .fa-star-o {
  color: rgba(255, 255, 255, 0.67);
  opacity: 0.5;
}

.profile-actions {
  margin-top: 45px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .profile-actions {
    margin-bottom: 45px;
    margin-top: -30px;
  }
}
@media (min-width: 992px) {
  .profile-actions {
    text-align: right;
  }
}
.profile-actions .btn {
  margin-right: 5px;
}
.profile-actions .btn.btn-primary {
  margin-right: 0px;
}
.profile-actions .btn.btn-primary i, .profile-actions .btn.btn-primary svg {
  margin-right: 8px;
}
.profile-actions .btn span {
  display: inline-block;
  margin-left: 5px;
}
.profile-actions .btn.btn-secondary:disabled {
  opacity: 1;
  background: #008c6a;
}
.profile-actions .btn.btn-error:disabled {
  opacity: 1;
  background: #f2262c;
}
.profile-actions .btn.btn-primary:disabled {
  opacity: 1;
  background: #6b21c2;
}

.profile .video-area {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.profile .video-area .left-arrow {
  position: absolute;
  left: 0px;
  right: auto;
  top: calc(50% - 20px);
}
.profile .video-area .right-arrow {
  position: absolute;
  right: 0px;
  left: auto;
  top: calc(50% - 20px);
}
.profile .video-area .left-arrow .btn i, .profile .video-area .left-arrow .btn svg,
.profile .video-area .right-arrow .btn i,
.profile .video-area .right-arrow .btn svg {
  color: #fff;
  font-size: 30px;
}
.profile .video-area:hover .left-arrow .btn,
.profile .video-area:hover .right-arrow .btn {
  opacity: 0.8;
}
.profile .video-area:hover .left-arrow .btn:hover,
.profile .video-area:hover .right-arrow .btn:hover {
  opacity: 1;
}
.profile .video-area .img-fluid {
  min-width: 100%;
}
.profile .lower-header .btn {
  height: 50px !important;
}
.profile .lower-header .btn.btn-error {
  width: calc(50% - 5px);
  margin-right: 5px;
  margin-bottom: 10px;
  float: left;
}
.profile .lower-header .btn.btn-secondary {
  width: calc(50% - 5px);
  margin-left: 5px;
  margin-right: 0px;
  margin-bottom: 10px;
  display: inline-block;
}
.profile .lower-header .btn.btn-primary {
  margin-bottom: 45px;
}
.profile .lower-header .username {
  margin-bottom: 15px;
}
.profile .lower-header .profile-actions {
  margin-top: 15px;
}
.profile .address {
  padding: 30px;
}
.profile .address a {
  color: #00bf91;
}
.profile .address h5 {
  margin-bottom: 15px;
}
.profile .address h5 i, .profile .address h5 svg {
  margin-right: 7px;
  position: relative;
  top: -1px;
}
.profile .address hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.profile ul.leaders {
  max-width: 100%;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
  color: rgba(255, 255, 255, 0.67);
}
.profile ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.2);
  content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
}
.profile ul.leaders span:first-child {
  padding-right: 0.33em;
  background: #242733;
}
.profile ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: #242733;
}
.profile .card:hover {
  transform: translateY(0) !important;
}

hr.white {
  border-color: rgba(255, 255, 255, 0.2);
}

.fa-margin-right {
  margin-right: 7px;
}

.share-buttons button {
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  height: 50px;
  width: 50px;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
}
.share-buttons button i,
.share-buttons button svg {
  font-size: 22px;
  position: relative;
  top: 2px;
}
.share-buttons button[aria-label=facebook] {
  background-color: #1877f2 !important;
  border-color: #1877f2 !important;
  color: white !important;
}
.share-buttons button[aria-label=twitter] {
  background-color: #1da1f2 !important;
  border-color: #1da1f2 !important;
  color: white !important;
}
.share-buttons button[aria-label=pinterest] {
  background-color: #bd081c !important;
  border-color: #bd081c !important;
  color: white !important;
}
.share-buttons button[aria-label=reddit] {
  background-color: #ff5700 !important;
  border-color: #ff5700 !important;
  color: white !important;
}
.share-buttons button[aria-label=whatsapp] {
  background-color: #25d366 !important;
  border-color: #25d366 !important;
  color: white !important;
}
.share-buttons button:hover {
  color: white !important;
}
.share-buttons button:hover[aria-label=facebook] {
  background-color: #0c64d5 !important;
  border-color: #0c64d5 !important;
}
.share-buttons button:hover[aria-label=twitter] {
  background-color: #0d8cda !important;
  border-color: #0d8cda !important;
}
.share-buttons button:hover[aria-label=pinterest] {
  background-color: #960616 !important;
  border-color: #960616 !important;
}
.share-buttons button:hover[aria-label=reddit] {
  background-color: #d64900 !important;
  border-color: #d64900 !important;
}
.share-buttons button:hover[aria-label=whatsapp] {
  background-color: #1fb055 !important;
  border-color: #1fb055 !important;
}

.comment-input-box {
  margin-bottom: 20px;
}
.comment-input-box .comment-input {
  margin-top: 20px;
  height: 150px !important;
  margin-bottom: 10px;
}

.comments {
  margin-top: 40px;
  margin-bottom: 30px;
}
.comments .card.comment {
  padding: 30px;
  position: relative;
  display: block;
  margin-bottom: 10px;
  margin-bottom: 0;
}
.comments .card.comment .left {
  width: 70px;
  display: inline-block;
  vertical-align: top;
}
.comments .card.comment .left img {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50px;
}
.comments .card.comment .right {
  display: inline-block;
  width: calc(100% - 70px);
  vertical-align: top;
}
.comments .card.comment .right span {
  font-size: 13px;
}
.comments .card.comment .right span.edit-buttons {
  float: right;
  visibility: hidden;
}
.comments .card.comment .right span.edit-buttons button {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  font-weight: bold;
}
.comments .card.comment .right span.edit-buttons button.edit {
  color: #00bf91;
  margin-right: 10px;
}
.comments .card.comment .right span.edit-buttons button.edit:hover {
  color: #00a67e;
}
.comments .card.comment .right span.edit-buttons button.delete {
  color: #f5565b;
}
.comments .card.comment .right span.edit-buttons button.delete:hover {
  color: #f43e44;
}
@media (max-width: 767px) {
  .comments .card.comment .right span.edit-buttons {
    float: none;
    display: block;
    visibility: visible;
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .comments .card.comment .right span.edit-buttons {
    visibility: visible;
  }
}
.comments .card.comment:hover .right .edit-buttons {
  visibility: visible;
}

.events-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 10px;
}
.events-container ul li {
  padding-bottom: 30px;
}
.events-container ul li .event {
  display: block;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  padding-bottom: 30px;
}
.events-container ul li .event .left {
  width: 200px;
  height: 200px;
  display: inline-block;
  vertical-align: top;
  background-color: #818db3;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.events-container ul li .event .left img {
  border-radius: 3px;
}
@media (max-width: 767px) {
  .events-container ul li .event .left {
    height: auto;
    width: 100%;
    margin-bottom: 15px;
  }
}
.events-container ul li .event .right {
  padding-left: 20px;
  display: inline-block;
  width: calc(100% - 200px);
  vertical-align: top;
}
.events-container ul li .event .right h6 {
  color: white;
  font-size: 18px;
  margin-bottom: 3px;
}
.events-container ul li .event .right .date-time {
  font-size: 15px;
}
@media (max-width: 767px) {
  .events-container ul li .event .right {
    width: 100%;
    margin-bottom: 15px;
    padding-left: 0;
  }
}
.events-container ul li .event .right button {
  margin-top: 10px;
  margin-bottom: 0px;
}
.events-container ul li:last-of-type {
  padding-bottom: 0;
}
.events-container ul li:last-of-type .event {
  padding-bottom: 0;
  border-bottom: none;
}

.drink-specials {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 10px;
}
.drink-specials li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.drink-specials li h6 {
  color: white;
}
.drink-specials li p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.drink-specials li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.drink-specials li .txt-btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
}
.drink-specials li .txt-btn i, .drink-specials li .txt-btn svg {
  margin-right: 5px;
}
.drink-specials li .txt-btn.like {
  color: #00bf91;
}
.drink-specials li .txt-btn.like:hover {
  color: #00a67e;
}
.drink-specials li .txt-btn.dislike {
  color: #f5565b;
  margin-right: 10px;
}
.drink-specials li .txt-btn.dislike:hover {
  color: #f43e44;
}
@media (max-width: 767px) {
  .drink-specials li .txt-btn {
    display: inline-block;
    width: auto;
  }
}

.player-area video {
  width: 100%;
  height: 100%;
}

.carousel-control-prev, .carousel-control-next {
  bottom: 50px;
  margin-top: 50px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.all-event-btn {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.87);
  outline: 0 !important;
  box-shadow: none !important;
}
.all-event-btn:hover {
  color: #00bf91;
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}
.nav-tabs .nav-item {
  background: none;
  border: none;
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.67);
  margin-right: 20px;
}
.nav-tabs .nav-item.active {
  background: none;
  border: none;
  border-bottom: solid 3px #00bf91;
  color: rgba(255, 255, 255, 0.67);
  color: white;
  font-weight: bold;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.view-all-button {
  display: inline-block;
  margin-top: 45px;
}

.list {
  padding-top: calc(65px + 30px);
}
.list .list-header h3 {
  margin-top: 30px;
}
.list .list-header .btn-link {
  padding: 0px;
  font-weight: normal;
  margin-bottom: 45px;
  width: auto;
}

@media (min-width: 768px) {
  .search {
    margin-top: 30px;
  }
}
.search #search {
  width: calc(100% - 165px);
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
@media (max-width: 991px) {
  .search #search {
    width: calc(100% - 95px);
  }
}
.search.with-dates #search {
  width: calc(100% - 365px);
  vertical-align: top;
  height: 48px;
}
.search.with-dates .dropdown .d-lg-inline {
  display: none !important;
}
.search.with-dates .dropdown-toggle {
  margin-right: 5px;
  width: 90px;
  vertical-align: top;
  height: 48px;
}
.search.with-dates .date-select {
  width: 265px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 991px) {
  .search.with-dates #search {
    width: calc(100% - 95px);
    vertical-align: top;
  }
  .search.with-dates .dropdown-toggle {
    margin-right: 0;
  }
  .search.with-dates .date-select {
    width: 100%;
    margin-top: 6px;
  }
}

.event-date {
  clear: both;
  display: block;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.67);
  font-size: 15px;
}

@media (min-width: 768px) {
  .admin-search {
    margin-top: 30px;
  }
}
.admin-search input {
  width: calc(100% - 300px);
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
@media (max-width: 991px) {
  .admin-search input {
    width: calc(100% - 230px);
  }
}
.admin-search.condensed {
  margin-top: 0;
}
.admin-search.condensed .dropdown,
.admin-search.condensed input .admin-add-button {
  vertical-align: top;
}
.admin-search.condensed .btn {
  height: 45px;
}

.infinite-loader-container {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.marker-icon {
  font-size: 30px;
  color: #8439dd;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.modal-inner {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: auto;
  width: 40%;
  background: #232734;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 30px;
  overflow: hidden;
  color: white;
}

.inner-card {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 20px;
  width: 100%;
}

.white-color {
  color: white !important;
}

.venue-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.rating {
  display: inline-block;
}
.rating i,
.rating svg {
  margin-right: 3px;
}
.rating .fa-star {
  color: #00bf91;
}
.rating .fa-star-o {
  color: rgba(0, 191, 145, 0.67);
  opacity: 0.5;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
}

.map-toggle {
  margin-top: 30px;
}

.map-container {
  height: 100vh;
  width: 100%;
  position: relative;
  border: thin #8439dd solid;
  padding: 4px;
  border-radius: 4px;
}

.map-loader {
  min-height: 100vh;
}
.map-loader > div {
  top: 50%;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  margin-top: -75px;
}
.contact {
  padding-top: 100px;
}
.contact .card {
  padding: 45px;
}
.contact .card textarea {
  height: 200px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.covid {
  padding-top: 100px;
  padding-bottom: 30px;
}
.covid .card {
  padding: 30px;
}
.covid .card ul {
  border-radius: 3px;
  padding: 0;
  margin: 0;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.covid .card ul li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.covid .card ul li a {
  display: block;
  width: 100%;
  padding: 15px;
  color: rgba(255, 255, 255, 0.87);
  background: rgba(255, 255, 255, 0.04);
  font-weight: bold;
}
.covid .card ul li a i,
.covid .card ul li a svg {
  float: right;
  opacity: 0.4;
  padding-top: 4px;
}
.covid .card ul li a:hover {
  background: #8439dd;
  color: white;
}
.covid .card ul li a:hover i,
.covid .card ul li a:hover svg {
  color: white;
}
.covid .card ul li:last-of-type {
  border-bottom: none;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.events-page .white {
  color: white !important;
}

.plyr--full-ui input[type=range] {
  color: #00bf91;
}

.plyr__control--overlaid {
  background: rgba(0, 191, 145, 0.8);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #00bf91;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(0, 191, 145, 0.5);
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #00bf91;
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__controls button:hover, .plyr__play-large {
  background: #00bf91;
}

.plyr__progress--played, .plyr__volume--display {
  background: #00bf91;
}

.plyr__progress--played, .plyr__volume--display {
  color: #00bf91;
}

@media (min-width: 768px) {
  .search-events {
    margin-top: 30px;
  }
}
.search-events #search {
  width: calc(100% - 165px);
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
@media (max-width: 991px) {
  .search-events #search {
    width: calc(100% - 95px);
  }
}
.search-events.with-dates #search {
  width: calc(100% - 450px);
  vertical-align: top;
  height: 48px;
}
.search-events.with-dates .dropdown .d-lg-inline {
  display: none !important;
}
.search-events.with-dates .dropdown-toggle {
  margin-right: 5px;
  vertical-align: top;
  height: 48px;
}
.search-events.with-dates .date-select {
  width: 265px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 991px) {
  .search-events.with-dates #search {
    width: calc(100% - 95px);
    vertical-align: top;
  }
  .search-events.with-dates .dropdown-toggle {
    margin-right: 0;
  }
  .search-events.with-dates .date-select {
    width: 100%;
    margin-top: 6px;
  }
}

.event-date {
  clear: both;
  display: block;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.67);
  font-size: 15px;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.username {
  display: block;
  margin-bottom: 46px;
  line-height: 1;
}
.username a {
  padding-left: 1px;
  color: rgba(255, 255, 255, 0.87) !important;
  opacity: 0.6;
  font-size: 15px;
}
.username a:hover {
  color: white !important;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center, .register .card {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.register {
  height: 100vh;
}
.register .row,
.register [class*=col-] {
  height: 100vh;
}
.register .card {
  padding: 40px;
  display: block;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  border: solid 1px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1200px) {
  .register .card {
    padding: 60px;
  }
}
.register .header__logo {
  margin-bottom: 20px;
}
.register__image {
  max-width: 80px;
  margin-bottom: 30px;
}
.register input {
  margin-bottom: 5px;
}
.register .btn--primary {
  margin: 20px 0 30px !important;
}
.register .btn {
  display: block;
  width: 100%;
  margin: 5px 0 30px !important;
}
.register .btn-link {
  margin-bottom: 0px !important;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center, .venue-register .card {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.venue-register {
  min-height: 100vh;
  margin-top: 100px;
}
.venue-register .card {
  padding: 40px;
  display: block;
  margin: 0 auto;
  text-align: center;
  border: solid 1px rgba(255, 255, 255, 0.05);
}
@media (min-width: 1200px) {
  .venue-register .card {
    padding: 60px;
  }
}
.venue-register .header__logo {
  margin-bottom: 20px;
}
.venue-register__image {
  max-width: 80px;
  margin-bottom: 30px;
}
.venue-register input {
  margin-bottom: 5px;
}
.venue-register .btn--primary {
  margin: 20px 0 30px !important;
}
.venue-register .btn {
  display: block;
  width: 100%;
  margin: 5px 0 30px !important;
}
.venue-register .btn-link {
  margin-bottom: 0px !important;
}
.venue-register .force-30 {
  margin-top: 45px !important;
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.specials-list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.specials-list li {
  border-radius: 3px;
  background: #242733;
  overflow: hidden;
  margin: 10px 0;
}
.specials-list li img {
  width: 205px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 991px) {
  .specials-list li img {
    display: none;
  }
}
.specials-list li .background-image {
  width: 205px;
  display: inline-block;
  vertical-align: top;
  min-height: 155px;
  background-size: cover;
}
@media (max-width: 991px) {
  .specials-list li .background-image {
    display: none;
  }
}
.specials-list li a .right {
  padding: 30px;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 445px);
}
.specials-list li a .right h4 {
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 22px;
}
.specials-list li a .right p {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 15px;
}
.specials-list li a .right .rating {
  display: inline-block;
}
.specials-list li a .right .rating i,
.specials-list li a .right .rating svg {
  margin-right: 3px;
}
.specials-list li a .right .rating .fa-star {
  color: #00bf91;
}
.specials-list li a .right .rating .fa-star-o {
  color: rgba(255, 255, 255, 0.67);
  opacity: 0.5;
}
@media (max-width: 991px) {
  .specials-list li a .right {
    width: calc(100% - 240px);
  }
}
@media (max-width: 767px) {
  .specials-list li a .right {
    width: 100%;
  }
}
.specials-list li a .buttons {
  display: inline-block;
  width: 240px;
  vertical-align: top;
  text-align: right;
  padding-top: 25px;
  padding-right: 15px;
}
.specials-list li a .buttons button {
  width: 110px;
}
.specials-list li a .buttons button.btn-error {
  margin-right: 5px;
}
.specials-list li a .buttons i,
.specials-list li a .buttons svg {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .specials-list li a .buttons {
    width: 100%;
    padding: 0 20px 20px;
  }
  .specials-list li a .buttons button {
    width: calc(50% - 5px) !important;
    display: inline-block;
  }
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

footer {
  background: #0a0c11;
  opacity: 1;
  padding: 90px 0px;
}
footer .logo {
  display: inline-block;
}
footer .logo a {
  display: block;
  height: 18px;
  width: 100%;
  margin-top: 10px;
}
footer .logo img {
  height: 35px;
}
footer ul {
  list-style-type: none;
  padding-left: 0;
}
footer ul li {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}
footer ul li a, footer ul li button {
  color: rgba(255, 255, 255, 0.8);
}
footer ul li a:hover, footer ul li button:hover {
  color: #8439dd;
}
footer ul li button {
  outline: 0;
  padding: 0;
  border: none;
  background: none;
}
footer label {
  font-weight: normal;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 15px;
  font-size: 15px;
}
footer .social {
  text-align: right;
  margin-top: 20px;
}
footer .social li {
  border-radius: 3px;
  display: inline-block;
  background: #242733;
  height: 45px;
  width: 45px;
  margin-right: 5px;
  text-align: center;
}
footer .social li a {
  display: block;
  width: 100%;
  height: 100%;
}
footer .social li i, footer .social li svg {
  font-size: 20px;
  margin-top: 13px;
}
footer .social li:hover {
  background: #8439dd;
}
footer .social li:hover i, footer .social li:hover svg {
  color: white;
}
@media (max-width: 767px) {
  footer {
    text-align: center;
  }
  footer .social {
    text-align: center;
  }
}
@media (max-width: 991px) {
  footer {
    padding-bottom: 150px;
  }
}

.footer-nav {
  display: none;
  transform: translateY(100px);
  transition: all 0.4s ease;
}
@media (max-width: 991px) {
  .footer-nav {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;
    height: auto;
    background: #111;
    padding-bottom: env(safe-area-inset-bottom);
    transform: translateY(0px);
  }
  .footer-nav ul {
    list-style: none;
    font-size: 0px;
    width: 100%;
    display: block;
    height: 60px;
    padding-left: 0px;
  }
  .footer-nav ul li {
    display: inline-block;
    padding-top: 2px;
    width: 20%;
    font-size: 13px;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .footer-nav ul li i, .footer-nav ul li svg {
    display: block;
    font-size: 20px;
    margin: 2px 0px;
    color: rgba(255, 255, 255, 0.9);
    height: 21px;
    margin: 0 auto;
    margin-bottom: 3px;
  }
  .footer-nav ul li span {
    display: block;
    color: rgba(255, 255, 255, 0.5);
  }
  .footer-nav ul li a {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
  .footer-nav ul li a:hover, .footer-nav ul li a:visited, .footer-nav ul li a:active, .footer-nav ul li a:focus {
    text-decoration: none !important;
    outline: 0;
  }
  .footer-nav ul li a:active i {
    color: #9a9a9a;
  }
  .footer-nav ul li a:active span {
    color: #888;
  }
}
.footer-nav.active {
  transform: translateY(0px);
}
@-webkit-keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@keyframes bounce {
  0% {
    bottom: 5%;
  }
  50% {
    bottom: 7%;
  }
  100% {
    bottom: 5%;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounce {
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(20px);
  }
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 15px);
    opacity: 0;
  }
}
.delayed {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
